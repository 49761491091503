import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { Box, Container, IconButton, LinearProgress, Skeleton, TextField, Typography,  Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GradientText from '../../components/GradientText';
import Footer from '../../components/Footer';
import Image from '../../components/Image';
import Countdown from 'react-countdown';
import { CountdownRender, checkProjectSuccess, convertDateString, dropsToXrp, formatNumber, showResponseResult, getTimeStatusText, isStarted, getAccountBalance } from '../../components/Global';
import Button from '../../components/Button';
import ReactPlayer from 'react-player/lazy';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Transactions from '../../components/Table';
import { AppContext } from '../..';
import { toast } from 'react-hot-toast';
import { xumm } from "../.."
import Twitter from '../../assets/images/twitter.svg';
import Discord from '../../assets/images/discord.svg';
import Telegram from '../../assets/images/telegram.svg';
import Medium from '../../assets/images/medium.svg';
import Website from '../../assets/images/website.svg';
import LoadingModal from '../../components/LoadingModal';
import Link from "../../components/Link"
import sdk from '@crossmarkio/sdk';
import "./style.scss";
import Checkbox from '@mui/material/Checkbox';
import { getCSRFToken, getJWT } from '../../utils';

ChartJS.register(ArcElement, Tooltip, Legend);
const faq = [{
  title: "What is the  XRPL PAD Launchpad?",
  content: "XRPL PAD Launchpad is a presale platform for project founders to launch, mint and generate liquidity for their new XRP based project."
}, {
  title: "How can we participate in a Presale?",
  content: "Click on the project you are interested in, make sure to read through the description and then enter the amount of xrp you wish to invest (min is 10 XRP). When the presale is over you can claim the tokens and receive on your wallet."
}, {
  title: "What if the presale fails?",
  content: "If the presale fails then the XRP is fully refundable to the buyer."
}, {
  title: "How to list my own project?",
  content: "Click Apply. Fill out the project details and then pay the listing fee of 300 XRP. If presale is successful, XRPL PAD only takes 5% of the total raised."
}, {
  title: "How to get in contact?",
  content: "Below is the link to our Discord server. Join and ask any questions you like!"
}]

const colors = [
  '#a3ff12',
  '#6d4afe',
  '#841183',
  '#f82552',
  '#14a0c0',
  '#841183',
];

const defaultData = {
  labels: [],
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: colors,
      borderWidth: 0,
      offset: 8,
      hoverOffset: 20
    },
  ],
};

const options = {
  cutout: "85%",
  layout: {
    padding: {
      top: 10,
      bottom: 10,
      left: 10,
      right: 10
    }
  },
  elements: {
    arc: {
      borderRadius: 4,
    },
  },
};

export default function Detail() {
  const { id } = useParams();

  const defaultSteps = [
    {
      label: 'Check project status',
      description: `We are checking the project status. Please wait a moment.`,
    },
    {
      label: 'Create a trustline',
      description: (<><p style={{fontWeight:"bold"}}>Please add a trustline to be able to receive the token once presale ends.</p><p style={{ color: 'red' }}>If on Xaman: check Event requests or Scan QR code </p><p style={{ color: 'green' }}>If on CrossMark: Confirm the transaction in your wallet</p></>),
      qr: true,
    },
    {
      label: 'Sending payment to',
      subtitle: process.env.REACT_APP_XRP_CONTRACT_ADDRESS,
      description: (<><p style={{fontWeight:"bold"}}>Please send the payment to our Escrow.</p><p style={{ color: 'red' }}>If on Xaman: check Event requests or Scan QR code </p><p style={{ color: 'green' }}>If on CrossMark: Confirm the transaction in your wallet</p></>),
      qr: true,
    },
    {
      label: 'Verify transaction',
      description: `Thank you for your investment. We are willing to see your next investment in other projects. Please wait while we are confirming your refund.`,
      tx: true,
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [openLoading, setOpenLoading] = useState(false);
  const [steps, setSteps] = useState(defaultSteps);
  const { address, projectsData } = useContext(AppContext);
  const [project, setProject] = useState({});
  const [distData, setDistData] = useState([]);
  const [chartConfig, setChartConfig] = useState(defaultData);
  const [key, setKey] = useState(0);
  const [amount, setAmount] = useState("");
  const [checked, setCheck] = useState(false);

  const label = { inputProps: { 'aria-label': 'terms check box' } };

  const success = checkProjectSuccess(project.status, project.soft_cap, project.token_raised);

  const closeLoadingModal = () => {
    setOpenLoading(false);
    setSteps(defaultSteps);
    setCurrentStep(0);
  }

  const getDistribution = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/distribution/${id}`);
      const data = await response.json();
      setDistData(data.result);

      const newConfig = { ...defaultData };
      newConfig.datasets[0].data = data?.result?.map(item => item.percentage) || [];
      setChartConfig(newConfig);

      setKey(prev => prev + 1);
    } catch (error) {
      console.log(error);
      toast.error("Error while fetching distribution data");
    }
  };

  useEffect(() => {
    const project = projectsData.find((project) => project?.id.toString() === id.toString());
    setProject(project || {});
    getDistribution();
  }, [id, projectsData]);

  useEffect(() => {
    const checkTerm = localStorage.getItem('checkTerm');
    if(checkTerm === "true") {
      setCheck(true);
    } else setCheck(false);
  }, []);

  const createTrustLine = async (cb) => {
    const res_purchase = await fetch(`${process.env.REACT_APP_SERVER_URL}/valid_buy`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        
        "authorization": `Bearer ${getJWT()}`
      },
      body: JSON.stringify({
        project_id: id,
        amount: amount
      })
    });

    const validPurchase = await res_purchase.json();

    if (!validPurchase.result) {
      closeLoadingModal();
      throw new Error(validPurchase.error);
    }

    if (!address) {
      closeLoadingModal();
      throw new Error("Please connect your wallet");
    }

    if (!project.token_symbol || !project.token_issuer || !project.token_total_supply) {
      closeLoadingModal();
      throw new Error("Can't find a token for this project");
    }

    const _crossmark = localStorage.getItem("crossmark");
    if (_crossmark) {
      const _steps = [...steps];
      _steps[1].qr_link = '';
      setSteps(_steps);
      setCurrentStep(1);

      const { response } = await sdk.methods.signAndSubmitAndWait({
        TransactionType: 'TrustSet',
        Account: address,
        LimitAmount: {
          currency: project.token_symbol,
          issuer: project.token_issuer,
          value: project.token_total_supply
        }
      });

      if(response.data.meta.isError || response.data.meta.isExpired || response.data.meta.isFail || response.data.meta.isPending || response.data.meta.isRejected) {
        closeLoadingModal();
        throw new Error("Transaction Failed.");
      }

      if(response.data.meta.isSuccess) {
        const txid = response.data?.resp?.result?.hash;
        if (txid) {
          toast.success("Trustset was established successfully.");
          const _steps = [...steps];
          _steps[2].qr_link = '';
          setSteps(_steps);
          setCurrentStep(2);
          cb();
        } else {
          closeLoadingModal();
          toast.error("Transaction rejected.");
        }
      }
      return response;
    }

    const _xaman = localStorage.getItem("XummPkceJwt");

    if (_xaman) {
      const payload = await xumm.payload?.createAndSubscribe({
        TransactionType: 'TrustSet',
        Account: address,
        LimitAmount: {
          currency: project.token_symbol,
          issuer: project.token_issuer,
          value: project.token_total_supply
        }
      }, async (event) => {
        if (Object.keys(event.data).indexOf('signed') > -1) {
          if (event.data.expires) {
            // Expires property exists, indicating expiration of sign-in
            closeLoadingModal();
            toast.error("Sign-in expired.");
            return;
          } else {
            const txid = event.data.txid;
            if (txid) {
              toast.success("Trustset was established successfully.");
              cb();
              return;
            } else {
              closeLoadingModal();
              toast.error("Transaction rejected.");
              return;
            }
          }
        }
      });
  
      if (payload) {
        xumm.xapp?.openSignRequest(payload.created);
        const _steps = [...steps];
        _steps[1].qr_link = payload.created.refs.qr_png;
        setSteps(_steps);
        setCurrentStep(1);
      }
  
      return payload;
    }
  }

  const onBuy = async () => {
    if (!amount || isNaN(Number(amount))) {
      toast.error("Input number correctly");
      return;
    }

    if(!checked) {
      toast.error("Check the terms and Conditions");
      return;
    }

    const walletBalance = await getAccountBalance(address);
    const xrpBalanceObj = walletBalance.find(item => item.currency === "XRP");
    const xrpBalance = xrpBalanceObj ? xrpBalanceObj.balance : 0;
    
    if(xrpBalance === 0 || Number(amount) > xrpBalance) {
      toast.error("There is not enough XRP.");
      return;
    }

    setCurrentStep(0);
    setOpenLoading(true);

    try {
      await createTrustLine(async () => {
        try {
          const tokenAmount = (amount * 10 ** process.env.REACT_APP_XRP_EXPONENT).toString();
          
          const _crossmark = localStorage.getItem("crossmark");
          if (_crossmark) {
            const { response } = await sdk.methods.signAndSubmitAndWait({
              TransactionType: 'Payment',
              Destination: process.env.REACT_APP_XRP_CONTRACT_ADDRESS,
              Account: address,
              Amount: tokenAmount,
            });

            if(response.data.meta.isError || response.data.meta.isExpired || response.data.meta.isFail || response.data.meta.isPending || response.data.meta.isRejected) {
              closeLoadingModal();
              toast.error("Transaction Failed.");
              return;
            } else {
              const txid = response.data?.resp?.result?.hash;
              if (txid) {
                const _steps = [...steps];
                _steps[3].subtitle = txid;
                setSteps(_steps);
                setCurrentStep(3);

                try {
                  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/buy`, {
                    method: "post",
                    headers: {
                      "Content-Type": "application/json",
                      
                      "authorization": `Bearer ${getJWT()}`
                    },
                    body: JSON.stringify({
                      project_id: id,
                      amount: amount,
                      address: address,
                      transaction_id: txid
                    })
                  });

                  const data = await response.json();
                  closeLoadingModal();

                  if (data?.error) {
                    toast.error(data?.error);
                    return;
                  } else {
                    toast.success("Successfully applied");
                  }

                } catch (error) {
                  closeLoadingModal();
                  console.log("applying error", error);
                  toast.error(error.message);
                  return;
                }
              } else {
                closeLoadingModal();
                toast.error("Transaction rejected.");
                return;
              }
            }
          }

          const _xaman = localStorage.getItem("XummPkceJwt");
          if (_xaman) {
            const payload = await xumm.payload?.createAndSubscribe({
              TransactionType: 'Payment',
              Destination: process.env.REACT_APP_XRP_CONTRACT_ADDRESS,
              Account: address,
              Amount: tokenAmount,
            }, async (event) => {
              if (Object.keys(event.data).indexOf('signed') > -1) {
                if (event.data.expires) {
                  // Expires property exists, indicating expiration of sign-in
                  closeLoadingModal();
                  toast.error("Sign-in expired.");
                  return;
                } else {
                  const txid = event.data.txid;
                  if (txid) {
                    const _steps = [...steps];
                    _steps[3].subtitle = txid;
                    setSteps(_steps);
                    setCurrentStep(3);
  
                    try {
                      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/buy`, {
                        method: "post",
                        headers: {
                          "Content-Type": "application/json",
                          
                          "authorization": `Bearer ${getJWT()}`
                        },
                        body: JSON.stringify({
                          project_id: id,
                          amount: amount,
                          address: address,
                          transaction_id: txid
                        })
                      });
  
                      const data = await response.json();
                      closeLoadingModal();
  
                      if (data?.error) {
                        toast.error(data?.error);
                        return;
                      } else {
                        toast.success("Successfully applied");
                        return;
                      }
  
                    } catch (error) {
                      closeLoadingModal();
                      console.log("applying error", error);
                      toast.error(error.message);
                      return;
                    }
                  } else {
                    closeLoadingModal();
                    toast.error("Transaction rejected.");
                    return;
                  }
                }
              }
            });
  
            if (payload) {
              xumm.xapp?.openSignRequest(payload.created);
  
              const _steps = [...steps];
              _steps[2].qr_link = payload.created.refs.qr_png;
              setSteps(_steps);
              setCurrentStep(2);
            }
  
            return payload;
          }
        } catch (err) {
          closeLoadingModal();
          toast.error(err.message);
          console.log(err)
        }
      });
    } catch (err) {
      closeLoadingModal();
      toast.error(err.message);
      console.log(err)
    }
  }

  const onSwap = () => {
    const url = `https://orchestra.finance/pools/XRP-${project.token_symbol}.${project.token_issuer}`;
    window.open(url, "_blank");
  }

  const handleChange = () => {
    setCheck(!checked);
    localStorage.setItem('checkTerm', !checked);
  }

  return (
    <>
      <Box className="detail-box">
        <LoadingModal open={openLoading} steps={steps} currentStep={currentStep} />
        <Container className="detail-container">
          <Navbar />
          {project.icon ?
            <Image src={project.icon} className="token-icon" /> :
            <Skeleton className="token-icon" variant="circular" />
          }
          <Box sx={{ textAlign: "center" }}>
          {project.website ?
              <Link href={project.website}>
                <IconButton className='project-social-icon'>
                  <img src={Website} alt="Website" /> 
                </IconButton>
              </Link> :
              <IconButton className='project-social-icon'>
                <Skeleton variant="rectangular">
                  <img src={Website} alt="Website" />
                </Skeleton>
              </IconButton>
            }
            {project.twitter &&
              <Link href={project.twitter}>
                <IconButton className='project-social-icon'>
                  <img src={Twitter} alt="Twitter" />
                </IconButton>
              </Link>
            }
            {project.discord &&
              <Link href={project.discord}>
                <IconButton className='project-social-icon'>
                  <img src={Discord} alt="Twitter" />
                </IconButton>
              </Link>
            }
            {project.telegram &&
              <Link href={project.telegram}>
                <IconButton className='project-social-icon'>
                  <img src={Telegram} alt="Twitter" />
                </IconButton>
              </Link>
            }
            {project.medium &&
              <Link href={project.medium}>
                <IconButton className='project-social-icon'>
                  <img src={Medium} alt="Twitter" />
                </IconButton>
              </Link>
            }

          </Box>
          <Box className="token-info">
            <Box className="token-name">
              {project.token_symbol ?
                <GradientText sizesmall={24} size={60}>{project.token_symbol}</GradientText> :
                <Skeleton variant="rectangular">
                  <GradientText sizesmall={24} size={60}>TST</GradientText>
                </Skeleton>
              }
              {project.token_name ?
                <GradientText sizesmall={16} size={24} top={15} topsmall={5} className="no-filter">
                  {project.token_name}
                </GradientText> :
                <Skeleton variant="rectangular">
                  <GradientText sizesmall={16} size={24} top={15} topsmall={5} className="no-filter">
                    Token Name Here
                  </GradientText>
                </Skeleton>
              }
            </Box>
            <Box className="token-launch">
              {project.status !== undefined && project.status !== null ?
                <Typography className='status-label'>Project {getTimeStatusText(project.status, success)}</Typography> :
                <Skeleton variant="rectangular">
                  <Typography className='status-label'>Project Ended</Typography>
                </Skeleton>
              }
              {project.start_time ?
                <Countdown date={convertDateString(isStarted(project.start_time) ? project.end_time : project.start_time)} renderer={CountdownRender}/> :
                <Skeleton variant="rectangular">
                  <Typography className='countdown'>00:00:00:00</Typography>
                </Skeleton>
              }
            </Box>
          </Box>
          {
            project.soft_cap !== undefined &&
            project.hard_cap !== undefined &&
            project.soft_cap !== null &&
            project.hard_cap !== null ?
              <Box className="progressbar">
                <Typography className='total-raised'>Total Raised: {formatNumber(dropsToXrp(project.token_raised || 0))} XRP</Typography>
                <LinearProgress
                  variant="buffer"
                  value={Math.min(project.token_raised / project.hard_cap * 100, 100)}
                  valueBuffer={Math.round(project.soft_cap / project.hard_cap * 100)}
                />
                <Typography
                  className='cap-label'
                  sx={{ width: `${Math.round(project.soft_cap / project.hard_cap * 100)}%` }}>
                  SoftCap
                  <br />
                  {formatNumber(dropsToXrp(project.soft_cap || 0))} XRP
                </Typography>
                <Typography
                  className='cap-label'
                  sx={{ width: "100%" }}>
                  HardCap
                  <br />
                  {formatNumber(dropsToXrp(project.hard_cap || 0))} XRP
                </Typography>
              </Box>
              :
              <Skeleton variant="rectangular" className="progressbar">
                <Box className="progressbar">
                  <Typography className='total-raised'>Total Raised</Typography>
                </Box>
              </Skeleton>
          }
          {
            success ? (<Link href={`/swap?currency=${encodeURIComponent(project.token_symbol)}&issuer=${encodeURIComponent(project.token_issuer)}`} style={{ textDecoration: 'none' }}><Box className="buy-form"><Button text="SWAP" /></Box></Link>) : 
            (
              <>
              <Box className="buy-form">
                <TextField label="Enter XRP amount to invest" className='small'
                  value={amount} onChange={(e) => setAmount(e.target.value)}
                  type="number" onWheel={(e) => e.target.blur()}
                />
                <Button text="Buy with XRP" onClick={onBuy} />
              </Box>
              <GradientText sizesmall={20} size={24} top={50} className="explore-subtitle">
                <Checkbox {...label} 
                  checked={checked}
                  onChange={handleChange}
                  sx={{
                    color: '#eeeeee'
                  }}/>
                <Link href="https://docs.xrplpad.io/launchpad-terms-and-conditions/for-users" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', fontWeight: 700, fontSize: 20 }}>
                  I agree to the Terms and Conditions
                </Link>
              </GradientText>
              </>
            )
          }
          <GradientText sizesmall={24} size={60} className="explore-title">
            Your Contributions
          </GradientText>
          <Transactions projectId={id} />
          <GradientText sizesmall={24} size={60} className="explore-title">
            Project Summary
          </GradientText>
          <Typography paragraph className='summary'>
            {project.description?.split('\n').map((paragraph, index) => {
                let text = paragraph;
                if (paragraph === "") {
                  text = <br />;
                }
                return (
                  <p key={index}>{text}</p>
                );
              })}
          </Typography>
          <ReactPlayer className='video' url={project.youtube} controls={true} />
          <GradientText sizesmall={24} size={60} className="explore-title">
            Tokenomics
          </GradientText>
          <Box className='chart'>
            <Doughnut key={key} data={chartConfig} options={options} />
            <Typography className='chart-center-text'><strong>Total Supply</strong><br />
              {formatNumber(project.token_total_supply || 0)} {project.token_symbol}
            </Typography>
          </Box>
          <Box className="datalabels">
            {distData?.map((item, i) => (
              <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
                <Box className="color-label" sx={{ backgroundColor: colors[i] }}></Box>
                <Typography sx={{ paddingLeft: "10px" }}>{item.title}: {item.percentage}%</Typography>
              </Box>
            ))}
          </Box>
          <Box className="datalabels">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ paddingLeft: "10px" }}>Raised XRP for Liquidity {project?.xrp_to_lp}%</Typography>
              </Box>
          </Box>
        </Container>
      </Box >
      <div className='detail-box'>
        <div className='content-panel'>
          <p className='prj-name'>Project Name</p>
          <div className='grid grid-cols-5'>
            <div className=' col-span-3 flex flex-col space-y-6'>
                <div className='w-[900px] h-[600px] bg-green-700'>

                </div>
                <div className='flex flex-col items-start prj-desc'>
                 <p className='title'>Description </p> 
                 <p className='content'>Project Description</p>
                </div>
            </div>
            <div className=' col-span-2 detail-panel'>
              <div className='flex space-x-2 justify-start items-center'>
                {project.icon ?
                  <Image src={project.icon} className="token-icon" /> :
                  <Skeleton className="token-icon" variant="circular" />
                }
                <div className='flex flex-col space-y-2'>
                  <p className='token-lbl'>{project.token_name?project.token_name:"Token Name"}</p>
                  <p className='token-lbl'>{project.token_symbol?project.token_symbol:"Token Symbol"}</p>
                </div>
              </div>
              <div className='time-panel'>               
                  {project.start_time ?
                    <Countdown date={convertDateString(isStarted(project.start_time) ? project.end_time : project.start_time)} renderer={CountdownRender}/> :
                    <Skeleton variant="rectangular">
                      <Typography className='countdown'>00:00:00:00</Typography>
                    </Skeleton>
                  }    
                  <p className='sub-desc'>Remaining...</p>              
              </div>
              <div className='raised-panel text-center'>
                <p className='lbl-amount'> {formatNumber(dropsToXrp(project.token_raised || 0))} XRP</p>
                <p className='lbl-amount'>Raised</p>
                <LinearProgress
                  sx={{margin:"10px 0px"}}
                  variant="buffer"
                  value={Math.min(project.token_raised / project.hard_cap * 100, 100)}
                  valueBuffer={Math.round(project.soft_cap / project.hard_cap * 100)}
                />
                <p className='lbl-amount'>{Math.min(project.token_raised / project.hard_cap * 100, 100)}%</p>
              </div>

              <div className="lbl-terms">
                <Checkbox {...label} 
                  checked={checked}
                  onChange={handleChange}
                  sx={{
                    color: '#eeeeee'
                  }}/>
                <Link href="https://docs.xrplpad.io/launchpad-terms-and-conditions/for-users" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', fontWeight: 700, fontSize: 20 }}>
                 I accept the terms and conditions
                </Link>
              </div>

              <div className="buy-form">
                <input  label="Enter XRP amount to invest"
                  placeholder='XRP'
                  value={amount} onChange={(e) => setAmount(e.target.value)}
                  type="number" onWheel={(e) => e.target.blur()}
                />
                <button onClick={onBuy}>Buy</button>
              </div>

              <div className='contribution-panel'>
                <p className="title">
                  Your Contributions
                </p>
                <Transactions projectId={id} />
              </div>

              <div className='flex justify-between px-[40px]  font-bold text-[30px] leading-[30px] mt-[30px] '>
                <div className='flex flex-col space-y-2'>
                <p className=' '>Soft Cap:</p>
                <p>{formatNumber(dropsToXrp(project.soft_cap || 0))} XRP</p>
                </div>
                <div className='flex flex-col space-y-2'>
                  <p>Hard Cap:</p>
                  <p>{formatNumber(dropsToXrp(project.hard_cap || 0))} XRP</p>
                </div>
              </div>

              <div className='mt-[30px] px-[40px]'>
                <p className='font-bold text-[30px] leading-[30px]'>Percentage to Liquidity Pool:</p>
                <p className='font-medium text-[30px] leading-[30px]'>Percentage number</p>
                <p className='font-bold text-[30px] leading-[30px] text-center mt-[30px]'>Distributions:</p>

              </div>
            
            </div>    
          </div>
        </div>
        <div className='mt-[50px] border-b-[4px] border-black'>
          <p className='text-center text-[60px] font-bold'>FAQs</p>
          {faq.map(data =>
            <Accordion key={data.title} >
              <AccordionSummary expandIcon={<ExpandMoreIcon color='black' />}>
                <p className='text-[30px] font-bold'>{data.title}</p>                
              </AccordionSummary>
              <AccordionDetails>
              <p className='text-[30px] font-medium'> {data.content}</p>
               
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      
      </div>
    </>
  )
}
