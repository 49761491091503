import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import "./style.scss";
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

export default function ContainerBox({ className, title, description, children, onClick, buttonText, hiddenButton, status }) {
    const [ btnData, setBtnData ] = useState({
        text:"",
        subText:"",
        size:"0px",
        underline:false,
        bg:"#000",
        color:"#fff",
        border: false,
        borderColor:"border-[#000]",
        disabled:true
    })

    useEffect(()=>{
        switch(status){
            case 1:
                setBtnData({
                    text:"Connect Wallet",
                    subText:undefined,
                    size: "text-[48px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break;
            case 2:
                setBtnData({
                    text:"Select Token",
                    subText:undefined,
                    size: "text-[48px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break;    
            case 3:
                setBtnData({
                    text:"Verifying Liquidity Pool",
                    subText:undefined,
                    size: "text-[36px]",
                    underline:true,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break; 
            case 4:
                setBtnData({
                    text:"No Liquidity Pool",
                    subText:undefined,
                    size: "text-[48px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break; 
            case 5:
                setBtnData({
                    text:"Insufficient Balance",
                    subText:undefined,
                    size: "text-[36px]",
                    underline:false,
                    bg:"bg-[#f00]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break;  
            case 6:
                setBtnData({
                    text:"Set Trustline",
                    subText:undefined,
                    size: "text-[48px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:false
                })
                break;
            case 7:
                setBtnData({
                    text:"Confirm Trustline in wallet",
                    subText:undefined,
                    size: "text-[31px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break;
            case 8:
                setBtnData({
                    text:"Verifying Trustline",
                    subText:undefined,
                    size: "text-[40px]",
                    underline:true,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
            break;             
            case 9:
                setBtnData({
                    text:"Trustline Failed",
                    subText:"refresh and retry",
                    size: "text-[32px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:false
                })
                break;
            case 10:
                setBtnData({
                    text:"SWAP NOW",
                    subText:undefined,
                    size: "text-[48px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:false
                })
                break;    
            case 11:
                setBtnData({
                    text:"Confirm swap in wallet",
                    subText:undefined,
                    size: "text-[36px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break; 
            case 12:
                setBtnData({
                    text:"Confirming Swap",
                    subText:undefined,
                    size: "text-[44px]",
                    underline:true,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:true
                })
                break; 
            case 13:
                setBtnData({
                    text:"Transaction Failed",
                    subText:"(Please try again)",
                    size: "text-[32px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:true,
                    borderColor:"border-[#f00]",
                    disabled:false
                })
                break;  
            case 14:
                setBtnData({
                    text:"Transaction Success",
                    subText:undefined,
                    size: "text-[32px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:true,
                    borderColor:"border-[#0f0]",
                    disabled:false
                })
                break;
            case 15:
                setBtnData({
                    text:"Transaction Rejected",
                    subText:undefined,
                    size: "text-[32px]",
                    underline:false,
                    bg:"bg-[#000]",
                    color:"text-[#fff]",
                    border:false,
                    disabled:false
                })
                break;   
                                       
            default:
                break;
        }
    },[status])

    return (
        <Box className={`containerbox-container ${className}`}>
            {
                // !hiddenButton &&
                // <>
                //     <img className='highlight-left' src={HighLightVertical} alt="" />
                //     <img className='highlight-top' src={HighLight} alt="" />
                //     <img className='highlight-right' src={HighLightVertical} alt="" />
                //     <img className='highlight-bottom' src={HighLight} alt="" />
                // </>
            }
            
            <Box className="box-content">
                <Box className="w-100">
                    <Box className="box-header">
                        <Typography className='title'>{title}</Typography>
                        {/* <Typography className='description'>{description}</Typography> */}
                    </Box>
                    <Box className="box-body w-100">
                        {children}
                    </Box>
                </Box>
                {                
                    !hiddenButton && <button onClick={onClick} disabled={btnData.disabled} className= {`btn-info ${btnData.bg} ${btnData.color} ${btnData.size} ${btnData.border && `border ${btnData.borderColor}`}`} >
                        <p> {btnData.text} {btnData.subText && (<br/>)} {btnData.subText && btnData.subText}</p>
                        {
                            btnData.underline && 
                            <div className='pt-4'>
                                <Stack sx={{ width: '35%', color: 'grey.500', margin:'auto' }} spacing={2}>
                                    <LinearProgress color="inherit" />
                                </Stack>
                            </div>
                            
                        }
                    </button>
                    // !hiddenButton && <button onClick={onClick} >{buttonText}</button>

                }
            </Box>
        </Box >
    )
}
