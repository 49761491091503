import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Backdrop, Modal, Fade, Input, TextField, InputAdornment, Grid, Tooltip, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button, IconButton } from '@mui/material';
import "./style.scss";
import { Search, Close } from '@mui/icons-material';
import { AppContext } from '../..';
import PlaceholderIcon from '../../assets/images/icon-placeholder.svg';
import { formatAddress, formatCurrency, formatTokenName, getTokenIcon, textToAsciiHexString } from '../Global';
import CenterBox from '../CenterBox';
import toast from 'react-hot-toast';
import { convertHextoString } from '../../pages/Leaderboard';
import axios from 'axios'
import { FiRefreshCw } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import XGOPng from '../../assets/images/XGO.png'
import XRDOGEPng from '../../assets/images/XRDOGE.jpeg'
import XRPHPng from '../../assets/images/XRPH.png'

const popularStaticList = [
    {
        currency: "SOLO", issuer:"rsoLo2S1kiGeCcn6hCUXVrCpGMWLrRrLZz"
    },
    {
        currency: "CORE", issuer:"rcoreNywaoz2ZCQ8Lg2EbSLnGuRBmun6D"
    },
    {
        currency: "CSC", issuer:"rCSCManTZ8ME9EoLrSHHYKW8PPwWMgkwr"
    },
    {
        currency: "XCORE", issuer:"r3dVizzUAS3U29WKaaSALqkieytA2LCoRe"
    },
    {
        currency: "ELS", issuer:"rHXuEaRYnnJHbDeuBH5w8yPh5uwNVh5zAg"
    },
    {
        currency: "XRdoge", issuer:"rLqUC2eCPohYvJCEBJ77eCCqVL2uEiczjA"
    },
    {
        currency: "XSpectar", issuer:"rh5jzTCdMRCVjQ7LT6zucjezC47KATkuvv"
    },
    {
        currency: "BTC", issuer:"rvYAfWj5gh67oV6fW32ZzP3Aw4Eubs59B"
    },
    {
        currency: "USD", issuer:"rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq"
    },
    {
        currency: "XGO", issuer:"rXGoXGoNeTTjPFVjzsmWWdFAMp7Lv3P4i"
    },
    {
        currency: "XRPH", issuer:"rM8hNqA3jRJ5Zgp3Xf3xzdZcx2G37guiZk"
    }
]

export default function TokenModal({ open, onClose, onSelect }) {
    const { balances } = useContext(AppContext);
    const [filterList, setFilterList] = useState(balances || []);
    const [popularList, setPopularList] = useState([]); 
    const [balancesWithData, setBalacesWithData] = useState(balances || []);

    const inputRef = useRef(null);
    const [openAddToken, setOpenAddToken] = useState(false);
    const [customIssuer, setCustomIssuer] = useState("");
    const [customToken, setCustomToken] = useState("");

    const [tokenNotErr, setTokenNotErr] = useState(false);

    const getFilterBalanceList = async (balances) => {
        const tokenBalances = balances.filter(token => token.currency.length === 3 || token.currency.endsWith("000000000000"))
        let filterListTmp = []
        let popularListTmp = []
        await Promise.all(tokenBalances.map(async (tokenBalance) => {
            if (tokenBalance.currency === "XRP") {
                filterListTmp.push({
                    ...tokenBalance,
                    tokenImage: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
                })
                return
            }
            const currency = tokenBalance.currency.length === 3 ? tokenBalance.currency : convertHextoString(tokenBalance.currency)
            const { data: tokenData } = await axios.get(`https://s1.xrplmeta.org/token/${currency}:${tokenBalance.account}`)
            // console.log('tokenData', tokenData)
            filterListTmp.push({
                ...tokenBalance,
                tokenImage: tokenData.meta.token.icon,
                price: tokenData.metrics.price
            })         

        }))
        setFilterList(filterListTmp)
         
        await Promise.all(popularStaticList.map(async ({currency, issuer})=>{
            try{
                const { data: tokenData } = await axios.get(`https://s1.xrplmeta.org/token/${currency}:${issuer}`)
                const tokenBalance = tokenBalances.find((balance)=>{
                    const currencyInMyList = balance.currency.length === 3 ? balance.currency : convertHextoString(balance.currency) 
                    if(currencyInMyList === currency && issuer === balance.account) return true
                    return false
                })

                let tokenImage = tokenData.meta.token.icon
                if(currency.toLowerCase() === 'xgo'){tokenImage = XGOPng} 
                if(currency.toLowerCase() === 'xrdoge'){tokenImage = XRDOGEPng}
                if(currency.toLowerCase() === 'xrph') {tokenImage = XRPHPng}
                if(currency.toLowerCase() == 'xcore') {tokenImage="https://cdn.xumm.pro/cdn-cgi/image/width=250,height=250,quality=75,fit=crop/token-avatars/core-rcoreNywaoz2ZCQ8Lg2EbSLnGuRBmun6D-asset.png"}

                if(tokenBalance){
                    popularListTmp.push({
                        ...tokenBalance,
                        tokenImage,
                        price: tokenData.metrics.price
                    }) 
                }else{
                    popularListTmp.push({
                        account: issuer,
                        balance:"0",
                        currency,
                        tokenImage,
                        price: tokenData.metrics.price
                    }) 
                }
                
            }catch(err){
                console.log('err')
            }
                  
        }))
        setPopularList(popularListTmp)
        console.log('popular list', popularListTmp)
        setBalacesWithData(filterListTmp)
    }

    function formatPrice(price) {
        const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

        if (isNaN(numericPrice)) {
            return "-";
        }

        return (numericPrice * 10 ** 6).toFixed(0) / (10 ** 6);
    }

    useEffect(() => {

        if (balances) {
            getFilterBalanceList(balances)

        }
    }, [balances]);

    const searchTokens = async() => {
        const searchText = inputRef.current.value.toLowerCase();
        if (balances) {
            const _balances = balancesWithData.filter(token => {
                if (token.currency.length > 3 && !token.currency.endsWith("000000000000"))
                    return false;

                const currency = formatCurrency(token.currency).toLowerCase();
                const issuer = token.account;
                return (currency.includes(searchText) || issuer?.includes(searchText));
            });

            setFilterList(_balances);
        }
    };

    const onSelectToken = (e, token) => {
        onClose(e);
        onSelect(token);
    }

    const onSelectCustomToken = async (e) => {
        if (!customToken || !customIssuer) {
            toast.error("Please enter currency code and issuer.");
            return;
        }
        const parsedCurrency = textToAsciiHexString(customToken);

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/check_token?currency=${parsedCurrency}&issuer=${customIssuer}`);
        const data = await response.json();
        if (!data.result) {
            // toast.error("Token Not Found.");
            setTokenNotErr(true)
            return;
        }
        setTokenNotErr(false)
        onClose(e);
        onSelect({
            account: customIssuer,
            currency: parsedCurrency,
        });
    }

    const onAddToken = () => {
        setOpenAddToken(prev => !prev);
    }

    const onClickClose = (e) => {
        if(openAddToken){
            setOpenAddToken(false)
            setTokenNotErr(false)
        }else{
            onClose(e)
        }
    }

    const onRefresh = () => {
        window.location.reload()
    }

    return (
        <Modal
            className="modal-wrapper"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box className="token-modal modal-content">
                    <IconButton className="close-button" onClick={onClickClose}>
                        <IoMdCloseCircleOutline size={48} />
                    </IconButton>
                    <Typography className='title'>Token List</Typography>
                    {
                        !openAddToken &&                    
                            <CenterBox className="search-box">
                                <TextField placeholder="Search popular tokens"                        
                                    name="search"
                                    inputRef={inputRef}
                                    onChange={searchTokens}
                                />
                                <CenterBox className="actions-box">
                                    <button
                                        variant='contained'
                                        // onClick={onAddToken}
                                    >                              
                                        <Search color='white' />                                    
                                    </button>
                                </CenterBox>
                            </CenterBox>
                    }
                    {!openAddToken && (inputRef.current?.value?.length === 0 || inputRef.current === null ) &&
                    <div className='mt-6'>
                        <p className='label'>Popular tokens:</p>
                        <Grid className='pinned-tokens' container spacing={2}>
                            {popularList.map((token, index) =>
                                    <Grid key={index} className='token' item xs="auto"
                                        onClick={(e) => onSelectToken(e, token)}
                                    >
                                        <Box
                                            component="img"
                                            src={ getTokenIcon(token)}
                                            alt="icon"
                                        />
                                        <Tooltip title={token.account}>
                                            <Typography>
                                                {formatCurrency(token.currency)}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                )}
                        </Grid>
                        <div className='divider'/>
                    </div>
                        
                    }
                    {openAddToken &&
                        <Box sx={{ margin: "10px 0" }}>
                            <TextField placeholder="Currency Code (case sensetive)" className='search'
                                onChange={(e) => setCustomToken(e.target.value)}
                            />
                            <TextField sx={{ margin: "10px 0" }} placeholder="Issuer" className='search'
                                onChange={(e) => setCustomIssuer(e.target.value)}
                            />
                            {
                                tokenNotErr &&
                                <p className='err'>
                                    Token Not Found
                                </p>
                            }
                            <button className='w-100 btn-black'
                                variant='contained'
                                onClick={(e) => onSelectCustomToken(e)}
                            >
                                Search
                            </button>
                        </Box>
                    }

                    {
                    !openAddToken &&
                        <>
                           
                            <div className='flex space-x-3 items-center'>
                                <p className='label'>
                                    My tokens
                                </p>                        
                                <button onClick={onRefresh}><FiRefreshCw/></button>
                            </div>

                            <List className='token-list'>
                                {filterList.length === 0 &&
                                    <Box className="error flex items-center justify-center">
                                        <div>
                                            <Typography className='error-title'>Tokens not loading?</Typography>
                                            <Typography className='error-title'>
                                                Try refreshing the page
                                            </Typography>
                                        </div>                                        
                                    </Box>
                                }
                                {filterList.map((token, index) =>
                                    <ListItem
                                        key={index}
                                        className='token-item'
                                        onClick={(e) => onSelectToken(e, token)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Box
                                                    component="img"
                                                    src={token.tokenImage}
                                                    alt="icon"
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            className='token-issuer'
                                            primary={formatCurrency(token.currency)}
                                            secondary={
                                                <Tooltip title={token.account}>
                                                    <Typography>
                                                        {token.account ? formatAddress(token.account) : ""}
                                                    </Typography>
                                                </Tooltip>}
                                        />
                                        <Typography>
                                            {formatPrice(token.balance)}
                                        </Typography>
                                    </ListItem>
                                )}
                            </List>
                            <div className='text-center pt-4'>
                            <button className='label' onClick={onAddToken}>Can’t Find? Click Here</button>

                            </div>
                        </>
                    }
                    
                </Box>
            </Fade>
        </Modal>
    )
}
