import React, { useContext } from 'react';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import "./style.scss";
import HighLight from "../../assets/images/highlight.svg";
import HighLightVertical from "../../assets/images/highlight-v.svg";
import { AppContext } from '../..';
import SuccessIcon from "../../assets/images/success.png";
import FailIcon from "../../assets/images/fail.png";
import Image from '../Image';
import Countdown from 'react-countdown';
import Twitter from '../../assets/images/twitter.svg';
import Discord from '../../assets/images/discord.svg';
import Telegram from '../../assets/images/telegram.svg';
import Medium from '../../assets/images/medium.svg';
import Website from '../../assets/images/website.svg';
import HoverCard from '../../assets/images/hover-card.svg';
import CenterBox from '../CenterBox';
import { dropsToXrp, formatNumber, checkProjectSuccess, getTimeStatusText, convertDateString, isStarted, CountdownRender } from '../Global';
import Link from "../Link";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

export default function ColorBox({ tokenData = {} }) {
    const { address } = useContext(AppContext);
    const navigate = useNavigate();
    const { id, icon, token_name, token_symbol, soft_cap, hard_cap, token_raised, start_time, end_time, twitter, discord, telegram, medium, website, status } = tokenData;

    const onClick = () => {
        // if (!address) {
        //     toast.error("Please connect xumm wallet");
        //     return;
        // }
        navigate(`/detail/${id}`);
    }

    const success = checkProjectSuccess(status, soft_cap, token_raised);

    return (

        <div className='box-container flex justify-around items-center '>
            <div className='flex space-x-12'>
                <div>
                    {icon ?
                        <Image src={icon} className="token-icon" /> :
                        <Skeleton className="token-icon" variant="circular" width={100} height={100} sx={{ scale: "0.6" }} />
                    }       
                </div>
                <div className='flex flex-col'>
                    <p className='token-desc'>Token name: {token_name?token_name:''}</p>
                    <p className='token-desc'>Supply:</p>
                    <p className='token-desc'>Soft Cap:{formatNumber(dropsToXrp(soft_cap || 0))} XRP</p>
                    <p className='token-desc'>Hard Cap:{ formatNumber(dropsToXrp(hard_cap || 0))} XRP</p>
                    <p className='token-desc'>Current raise: {formatNumber(dropsToXrp(token_raised || 0))} XRP</p>
                    <div className='flex space-x-2'>
                    {twitter &&
                                <Link href={twitter}>
                                    <IconButton className='social-icon'>
                                        <img src={Twitter} alt="Twitter" />
                                    </IconButton>
                                </Link>
                            }
                            {discord &&
                                <Link href={discord}>
                                    <IconButton className='social-icon'>
                                        <img src={Discord} alt="Twitter" />
                                    </IconButton>
                                </Link>
                            }
                            {telegram &&
                                <Link href={telegram}>
                                    <IconButton className='social-icon'>
                                        <img src={Telegram} alt="Twitter" />
                                    </IconButton>
                                </Link>
                            }
                            {medium &&
                                <Link href={medium}>
                                    <IconButton className='social-icon'>
                                        <img src={Medium} alt="Twitter" />
                                    </IconButton>
                                </Link>
                            }
                            {website ?
                                <Link href={website}>
                                    <IconButton className='social-icon'>
                                        <img src={Website} alt="Website" />
                                    </IconButton>
                                </Link> :
                                <IconButton className='social-icon'>
                                    <Skeleton variant="rectangular">
                                        <img src={Website} alt="Website" />
                                    </Skeleton>
                                </IconButton>
                            }
                    </div>
                </div>
            </div>

            <div className='flex flex-col items-center space-y-6'>
                <p className='lbl-time'>Time till End</p>
                {start_time ?
                    <Countdown date={convertDateString(isStarted(start_time) ? end_time : start_time)} renderer={CountdownRender} /> :
                    <Skeleton variant="rectangular">
                        <Typography className='countdown'>00:00:00:00</Typography>
                    </Skeleton>
                }
                <button className='btn-view' onClick={onClick}>View</button>
            </div>                      
        </div>
       
    )
}
