import React, {useEffect, useState, useContext, useCallback} from 'react'
import { useAccount, useEnsName } from 'wagmi';
import { Connector, useConnect } from 'wagmi';
import { ethers } from 'ethers';
import sdk from '@crossmarkio/sdk';
import { shortenAddress } from '../../utils';
import Navbar from '../../components/Navbar';
import "./style.scss";
import ContainerBox from '../../components/ContainerBox';
import ETHIcon from '../../assets/images/eth.png'
import MetaMaskIcon from '../../assets/images/metamask.png'
import XamanIcon from '../../assets/images/xaman.png'
import CrossMarkIcon from '../../assets/images/crossmark.png'
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, IconButton } from '@mui/material';
import SwitcherIcon from '../../assets/images/switcher.svg';
import toast from 'react-hot-toast';
import { AppContext } from '../..';
import LoadingModal from '../../components/LoadingModal';
import { getCSRFToken, getJWT,  wait } from '../../utils';
import contractABI from '../../ABI/ABI.json'
import { xumm } from "../.."
import { getAccountBalance } from '../../components/Global'
const fee = 0.1/100

const defaultSteps = [
  {
    label: 'Creating a trustline',
    description: `Please add a trustline to your wallet, so you can receive WETH.`,
    qr: true,
  },
  {
    label: 'Sending ETH to XRPL',
    description: `This is sending ETH to be converted to WETH .`,
  },
  {
    label: 'Confirming ETH transaction',
    description: `Confirming if ETH transaction is correct.`,
    tx: true,
  },  
  {
    label: 'Confirmed your transaction. ',
    description: `Check your XRP wallet few seconds later.`,
  },
];

//Steps in converting from XRP to ETH
const otherSteps = [
  {
    label: 'Sending payment to',
    subtitle: process.env.REACT_APP_XRP_BRIDGE_ADDRESS,
    description: (<><p style={{fontWeight:"bold"}}>Please send the payment to Bridge.</p><p style={{ color: 'red' }}>If on Xaman: check Event requests or Scan QR code </p><p style={{ color: 'green' }}>If on CrossMark: Confirm the transaction in your wallet</p></>),
    qr: true,
  },
  {
    label: 'Verifying transaction',
    description: `Verifying your transaction on XRPL.`,
    tx: true,
  },  
  {
    label: 'Confirmed your transaction. ',
    description: `Check your XRP wallet few seconds later.`,
  },
];


const WETH = process.env.REACT_APP_WETH
const WETH_ISSUER = process.env.REACT_APP_WETH_ISSUER
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS 
const BRIDGE_ADDRESS = process.env.REACT_APP_XRP_BRIDGE_ADDRESS

console.log(WETH, WETH_ISSUER, CONTRACT_ADDRESS, BRIDGE_ADDRESS)

export default function Bridge() {
  const { address, balances, setBalances } = useContext(AppContext);
  const { address: ethAddress } = useAccount()
  const { connectors } = useConnect()
  const [ mMConnector, setMMConnector ] = useState(undefined)
  const [ hasTrustLine, setHasTrustLine] = useState(false)
  const [steps, setSteps] = useState(defaultSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [ethBalances, setEthBalances] = useState({
    eth:0,
    xrp:0
  })
  const [ fromAsset, setFromAsset ] = useState({
    networkIcon: ETHIcon,
    networkName: "Ethereum",
    assetName: "ETH",
    amount: 0.0001,
    walletIcon: MetaMaskIcon,
    walletAddress: ethAddress
  })

  const [ toAsset, setToAsset ] = useState({
    networkIcon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png",
    networkName: "XRPL",
    assetName: "WETH",
    amount: (1-fee) * 500,
    walletIcon: localStorage.getItem("XummPkceJwt")?XamanIcon:CrossMarkIcon,
    walletAddress: address
  })

  const checkTrustline = useCallback(() => {
    let existTrustline = false;
    balances.forEach((balance) => {
        if (balance.currency === WETH && balance.account === WETH_ISSUER) {
            existTrustline = true;
            console.log('xrp balance', balance)
            setEthBalances(v=>({...v, xrp:balance.balance}))
            console.log('has')
        }
    });
    return existTrustline

  },[balances])

  useEffect(()=>{
    balances.forEach((balance) => {
      if (balance.currency === WETH && balance.account === WETH_ISSUER) {
          console.log('xrp balance', balance)
          setEthBalances(v=>({...v, xrp:balance.balance}))
          console.log('has')
      }
  });
  },[balances])

  useEffect(()=>{
      console.log('ethAddress', ethAddress)
      console.log('connectors', connectors)
      const mmConnect = connectors.find((connector)=>connector.name =="MetaMask")
      if(!mmConnect){
        toast.error("Can't find Metamask wallet!")
        return
      }
      setMMConnector(mmConnect)
  },[ethAddress, connectors])

  useEffect(()=>{
    if(toAsset.networkName == "XRPL"){
      setToAsset({
        networkIcon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png",
        networkName: "XRPL",
        assetName: "WETH",
        amount: (1-fee) * fromAsset.amount,
        walletIcon: localStorage.getItem("XummPkceJwt")?XamanIcon:CrossMarkIcon,
        walletAddress: address
      })
    }else{
      setFromAsset({
        networkIcon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png",
        networkName: "XRPL",
        assetName: "WETH",
        amount: fromAsset.amount,
        walletIcon: localStorage.getItem("XummPkceJwt")?XamanIcon:CrossMarkIcon,
        walletAddress: address
      })
    }
  },[address])

  useEffect(()=>{
    if(toAsset.networkName == "Ethereum"){
      setToAsset({
        networkIcon: ETHIcon,
        networkName: "Ethereum",
        assetName: "ETH",
        amount: (1-fee) * fromAsset.amount,
        walletIcon: MetaMaskIcon,
        walletAddress: ethAddress
      })
    }else{
      setFromAsset({
        networkIcon: ETHIcon,
        networkName: "Ethereum",
        assetName: "ETH",
        amount: fromAsset.amount,
        walletIcon: MetaMaskIcon,
        walletAddress: ethAddress
      })
    }
    if(ethAddress) getETHBalance(ethAddress)
  },[ethAddress])

  const  getETHBalance = async (ethAddress) => {
    const provider = new ethers.BrowserProvider(window.ethereum)
    const balance = await provider.getBalance(ethAddress);
    console.log('eth balance', balance)
    setEthBalances(v=>({...v, eth: ethers.formatEther(balance)}))
  }

  const handleMM = () => {
    console.log('handleConnectMM')
    if(!mMConnector){
      toast.error("Can't find Metamask wallet!")
      return
    }
    console.log('connecg', mMConnector)
    ethAddress? mMConnector.disconnect() : mMConnector.connect()
  }

  const handleSwitch = () => {
    const fromAssetTmp = {...fromAsset}
    console.log(fromAssetTmp)
    setFromAsset({...toAsset, amount:0})
    setToAsset({...fromAssetTmp, amount:0})
    // setHasTrustLine(v=>!v)
  }

  const handleChangeFromAmount = (newAmount) => {
    if(newAmount > (fromAsset.assetName==='ETH' ? ethBalances.eth: ethBalances.xrp)){
      toast.error("You have insufficient funds")
      return
    }

    setFromAsset(v=>(
      {
        ...v,
        amount: newAmount
      }
    ))

    setToAsset(v=>(
      {
        ...v,
        amount: (1-fee) * newAmount
      }
    ))
  }

  const closeLoadingModal = () => {
    setOpen(false);
    setSteps(defaultSteps);
    setCurrentStep(0);
  }

  const createTrustLine = async (cb, amount) => {
    const _crossmark = localStorage.getItem("crossmark");
    if (_crossmark) {
      const _steps = [...steps];
      _steps[0].qr_link = '';
      setSteps(_steps);
      // setCurrentStep(1);

      const { response } = await sdk.methods.signAndSubmitAndWait({
        TransactionType: 'TrustSet',
        Account: address,
        LimitAmount: {
          currency: WETH,
          issuer: WETH_ISSUER,
          value: '10000000000000000'
        }
      });

      if(response.data.meta.isError || response.data.meta.isExpired || response.data.meta.isFail || response.data.meta.isPending || response.data.meta.isRejected) {
        closeLoadingModal();
        throw new Error("Transaction Failed.");
      }

      if(response.data.meta.isSuccess) {
        const txid = response.data?.resp?.result?.hash;
        if (txid) {
          toast.success("Trustset was established successfully.");
          const _steps = [...steps];
          _steps[1].qr_link = '';
          setSteps(_steps);
          setCurrentStep(1);
          cb();
        } else {
          closeLoadingModal();
          toast.error("Transaction rejected.");
        }
      }

      return response;
    }

    const _xaman = localStorage.getItem("XummPkceJwt");

    if (_xaman) {
      const payload = await xumm.payload?.createAndSubscribe({
        TransactionType: 'TrustSet',
        Account: address,
        LimitAmount: {
          currency: WETH,
          issuer: WETH_ISSUER,
          value: '10000000000000000'
        }
      }, async (event) => {
        if (Object.keys(event.data).indexOf('signed') > -1) {
          if (event.data.expires) {
            // Expires property exists, indicating expiration of sign-in
            closeLoadingModal();
            toast.error("Sign-in expired.");
            return;
          } else {
            const txid = event.data.txid;
            if (txid) {
              toast.success("Trustset was established successfully.");
              setCurrentStep(1);
              cb();
              return;
            } else {
              closeLoadingModal();
              toast.error("Transaction rejected.");
              return;
            }
          }
        }
      });
  
      if (payload) {
        xumm.xapp?.openSignRequest(payload.created);
        const _steps = [...steps];
        _steps[0].qr_link = payload.created.refs.qr_png;
        setSteps(_steps);
        // setCurrentStep(1);
      }
  
      return payload;
    }
  }

  const sendETH = async (receiver, amount) => {
    const provider = new ethers.BrowserProvider(window.ethereum)
    console.log(contractABI, 'contractABI')
    const contract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, await provider.getSigner());
    const amountInWei = ethers.parseEther(amount);
    const tx = await contract.lockETH(receiver, { value: amountInWei });
    setCurrentStep(2)
    console.log('Transaction hash:', tx.hash);
    const receipt = await tx.wait();
    const _steps = [...steps];
    _steps[2].tx = tx.hash;
    setSteps(_steps);
    setCurrentStep(3)
    await wait(5);
    closeLoadingModal()
  }

  const convertETHToXRP = async () => {

    // await sendETH(address, "0.0001");
    setSteps(defaultSteps)
    setOpen(true)
    if(checkTrustline()){
      setCurrentStep(1)
      try {
        await sendETH(address, fromAsset.amount.toString());
      } catch (err) {
        closeLoadingModal();
        toast.error(err.message);
        console.log(err)
      }
    }else{
      try {
        await createTrustLine(async () => {
          try {
            await sendETH(address, fromAsset.amount.toString());
          } catch (err) {
            closeLoadingModal();
            toast.error(err.message);
            console.log(err)
          }
        }, fromAsset.amount.toString());
      } catch (err) {
        closeLoadingModal();
        toast.error(err.message);
        console.log(err)
      }
    } 
  }

  const convertXRPToETH = async (address) => {
    setSteps([...otherSteps])
    setCurrentStep(0)
    setOpen(true)
    try {
      const tokenAmount = fromAsset.amount.toFixed(6)
      
      const _crossmark = localStorage.getItem("crossmark");
      if (_crossmark) {
        const { response } = await sdk.methods.signAndSubmitAndWait({
          TransactionType: 'Payment',
          Destination: BRIDGE_ADDRESS,
          Account: address,
          Amount : {
            currency : WETH,
            value : tokenAmount,
            issuer : WETH_ISSUER
          },
        });

        if(response.data.meta.isError || response.data.meta.isExpired || response.data.meta.isFail || response.data.meta.isPending || response.data.meta.isRejected) {
          closeLoadingModal();
          toast.error("Transaction Failed.");
          return;
        } else {
          const txid = response.data?.resp?.result?.hash;
          if (txid) {
            const _steps = [...otherSteps];
            _steps[1].subtitle = txid;
            setSteps(_steps);
            setCurrentStep(1);

            try {
              const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/xrp_to_eth`, {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  
                  "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                  amount: Number(tokenAmount),
                  address: address,
                  receiver: toAsset.walletAddress,
                  transaction_id: txid
                })
              });

              const data = await response.json();
              setCurrentStep(2)
              await wait(5);
              closeLoadingModal();

              if (data?.error) {
                toast.error(data?.error);
                return;
              } else {
                toast.success("Successfully applied");
              }

            } catch (error) {
              closeLoadingModal();
              console.log("applying error", error);
              toast.error(error.message);
              return;
            }
          } else {
            closeLoadingModal();
            toast.error("Transaction rejected.");
            return;
          }
        }
      }

      const _xaman = localStorage.getItem("XummPkceJwt");
      if (_xaman) {
        console.log(BRIDGE_ADDRESS, address, tokenAmount)
        const payload = await xumm.payload?.createAndSubscribe({
          TransactionType: 'Payment',
          Destination: BRIDGE_ADDRESS,
          Account: address,
          Amount : {
            currency : WETH,
            value : tokenAmount,
            issuer : WETH_ISSUER
          },
        }, async (event) => {
          if (Object.keys(event.data).indexOf('signed') > -1) {
            if (event.data.expires) {
              // Expires property exists, indicating expiration of sign-in
              closeLoadingModal();
              toast.error("Sign-in expired.");
              return;
            } else {
              const txid = event.data.txid;
              if (txid) {
                const _steps = [...otherSteps];
                _steps[1].subtitle = txid;
                setSteps(_steps);
                setCurrentStep(1);
                console.log('toAsset.address', toAsset.walletAddress)
                try {
                  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/xrp_to_eth`, {
                    method: "post",
                    headers: {
                      "Content-Type": "application/json",
                      
                      "authorization": `Bearer ${getJWT()}`
                    },
                    body: JSON.stringify({
                      amount: Number(tokenAmount),
                      address: address,
                      transaction_id: txid,
                      receiver: toAsset.walletAddress,
                    })
                  });

                  const data = await response.json();
                  setCurrentStep(2)
                  await wait(5);
                  closeLoadingModal();

                  if (data?.error) {
                    toast.error(data?.error);
                    return;
                  } else {
                    toast.success("Successfully applied");
                    return;
                  }

                } catch (error) {
                  closeLoadingModal();
                  console.log("applying error", error);
                  toast.error(error.message);
                  return;
                }
              } else {
                closeLoadingModal();
                toast.error("Transaction rejected.");
                return;
              }
            }
          }
        });

        if (payload) {
          xumm.xapp?.openSignRequest(payload.created);

          const _steps = [...otherSteps];
          _steps[0].qr_link = payload.created.refs.qr_png;
          setSteps(_steps);
          setCurrentStep(0);
        }

        return payload;
      }
    } catch (err) {
      closeLoadingModal();
      toast.error(err.message);
      console.log(err)
    }
  }

  const handleConvert = async () => {
    if(!ethAddress){
      toast.error("Connect Metamask Wallet.")
      return
    }
    if(!address){
      toast.error("Connect to XRP Wallet")
      return
    }
    const chainId = await mMConnector.getChainId()
    console.log('chainId in get eth balance ', chainId)
    //11155111: Sepolia, 1: Ethereum 
    if(chainId !== 11155111){
      toast.error("Invalid Chain. Switch to Sepolia and try again.")
      return;
    }

    if(fromAsset.assetName === "ETH"){
      await convertETHToXRP()
    }else{
      await convertXRPToETH(address)
    }
  }

  return (
    <Box className="bridge-box">
      <Navbar />
      <LoadingModal open={open} steps={steps} currentStep={currentStep} />
      <div className='  w-full flex justify-center'>
        <div className='box-content  text-white relative'>
          <div className='flex flex-col space-y-3 border-b border-[#4162B7] pb-[20px] mb-8'>          
            <div className='flex flex-col space-y-3'>
              <div className='flex justify-between'>
                <div className=' flex flex-col'>
                  <Typography>From</Typography>
                  <div className='flex space-x-2 items-center bg-[#352775] rounded-md px-6 h-[37px]'>
                    <img src={fromAsset.networkIcon} alt={'Token logo'} className='w-[30px]'/>
                    <Typography className='pr-[8px]'>{fromAsset.networkName}</Typography>
                  </div>
                </div>
                <div className=' flex flex-col'>
                  <Typography>Send</Typography>
                  <div className='flex items-center space-x-2 bg-[#352775] rounded-md w-[300px] px-6'>
                    <input type='number' value={fromAsset.amount} onChange={(e)=>handleChangeFromAmount( Number(e.target.value))} className=' bg-transparent  h-[37px] outline-none border-none w-[50px]' />
                    <p>({fromAsset.assetName})</p>
                  </div>
                </div>
              </div>
              <div className='flex justify-between'>
                  <p>Balance: {fromAsset.assetName==='ETH' ? ethBalances.eth: ethBalances.xrp}</p>
                  <button onClick={()=>handleChangeFromAmount(fromAsset.assetName==='ETH' ? ethBalances.eth: ethBalances.xrp)}
                  >MAX</button>
          
              </div>
              <div className='flex space-x-2 items-center'>
                <img src={fromAsset.walletIcon} alt={'Token logo'} className=' h-[20px]'/>
                <input type='text' value={fromAsset.walletAddress?fromAsset.walletAddress:""} className=' bg-[#352775]/20 rounded-md px-6 h-[37px] outline-none border-none w-full' />
              </div>
            </div>

            <div className='flex justify-center'>
              <button className='flex justify-center items-center w-[30px] h-[30px] border rounded-full border-[#4162B7]' onClick={handleSwitch}>
                <img  src={SwitcherIcon}  alt='Switcher'/>
              </button>
            </div>

            <div className='flex flex-col space-y-3'>
              <div className='flex justify-between'>
                <div className=' flex flex-col'>
                  <Typography>To</Typography>
                  <div className='flex space-x-2 items-center bg-[#352775] rounded-md px-6 h-[37px]'>
                    <img src={toAsset.networkIcon} alt={'Token logo'} className='w-[30px]'/>
                    <Typography className='pr-[8px]'>{toAsset.networkName}</Typography>
                  </div>
                </div>
                <div className=' flex flex-col'>
                  <Typography>Receive</Typography>
                  <div className='flex items-center space-x-2 bg-[#352775] rounded-md w-[300px] px-6'>
                    <input type='number' value={toAsset.amount} className=' bg-transparent  h-[37px] outline-none border-none w-[50px]' />
                    <p>({toAsset.assetName})</p>
                  </div>
                </div>
              </div>
              <div className='flex space-x-2 items-center'>
                <img src={toAsset.walletIcon} alt={'Token logo'} className=' h-[20px]'/>
                <input type='text' value={toAsset.walletAddress?toAsset.walletAddress:""} className=' bg-[#352775]/20 rounded-md px-6 h-[37px] outline-none border-none w-full' />
              </div>
            </div>
          </div>
          <div className='flex flex-col space-y-2'>
            <div className='flex justify-between'>
              <div>
                <span className='pr-2'>Fee</span>
                <span>(0.1%)</span>                
              </div>
              <div>
                <input type='number' value={fromAsset.amount*0.001} className='outline-none border-none bg-transparent text-right pr-2'/>
                <span>
                  ETH                  
                </span>
              </div>
            </div>
            <div className='flex justify-between'>
              <p>
                Minimum Transcation
              </p>
              <p>
                50 ETH
              </p>
            </div>
            <div className='flex justify-between'>
              <p>
                Maximum Transaction
              </p>
              <p>
                20,00 ETH
              </p>
            </div>
          </div>

          <div className='flex justify-center bg-[#352775]/50 mt-2'>
              <button className='py-1'onClick={handleConvert}>Convert</button>
          </div>
          <div className=' absolute w-[350px] top-6 -right-[400px] p-4'>
            <button className='flex space-x-2 items-center' onClick={handleMM}>
              <img src={MetaMaskIcon} alt={'Token logo'} className='w-[25px] h-[25px]'/>
              {
                ethAddress ? <p className=' font-semibold '> {shortenAddress(ethAddress)} : Disconnect</p>
                :<p className=' font-semibold'>Connect</p>
              }
            </button>
            {
              address &&
              <div className='flex space-x-2 items-center mt-4'>
                <img src={localStorage.getItem("XummPkceJwt")?XamanIcon:CrossMarkIcon} alt={'Token logo'} className='w-[80px] h-[16px]'/>
                <p className=' font-semibold'>{shortenAddress(address)}</p>
              </div>
            }

          </div>
        </div>

      </div>
    </Box >
  )
}
