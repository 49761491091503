import React, { useEffect, useState } from "react";
import Image from '../../components/Image';
import Navbar from "../../components/Navbar";
import "./style.scss";
import { getAMMInfo } from '../../components/Global';
import PlaceholderIcon from '../../assets/images/icon-placeholder.svg';
import successImage from '../../assets/images/success.png';
import failImage from '../../assets/images/fail.png';
import xcoreIcon from '../../assets/images/XCORE.jpg';
import xpunkIcon from '../../assets/images/XPUNK.jpg';
import xrdogeIcon from '../../assets/images/XRdoge.jpg';
import vgbIcon from '../../assets/images/VGB.jpg';
import xrshibIcon from '../../assets/images/XRShib.jpg';
import xogeIcon from '../../assets/images/Xoge.jpg';
import xmemeIcon from '../../assets/images/xmeme.jpg';
import { Box, Container, Button, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CenterBox from "../../components/CenterBox";
import { Link } from 'react-router-dom';
import PaginatedItems from '../../PaginatedItems';
import axios from "axios";
import { redirect } from "react-router-dom";
import { fi } from "date-fns/locale";
const stepsCheckTokenPair = [
  {
    label: 'Verifying Token Pair',
    // subtitle: "Please wait while we are checking the exsiting token pairs.",
    description: "Checking if the Liquidity pools exists"
  },
];
const useStyles = makeStyles({
  flexGrow: {
    flex: '1',
  },
  button: {
    backgroundColor: '#5f6ffd !important',
    borderRadius: "100px !important",
    marginRight: "5px !important",
    width: "400px !important",
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#8992e5 !important',
      color: '#3c52b2 !important',
    },
  }
})

export default function Leaderboard() {
  const [curBoard, setCurBoard] = useState(1)
  const [allPoolData, setAllPoolData] = useState([]);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getData();
  }, [])
  const getData = async () => {

    let poolsTemp = []
    try {
      setLoading(true)
      const { data: pools } = await axios.get(`https://api.xrpscan.com/api/v1/amm/pools`);
      for (let i = 0; i < 100; i++) {
        const pool = pools[i]
        const issuer = pool.Asset2.issuer
        const currency = pool.Asset2.currency
        const asset1Name = 'XRP'
        const asset1Icon = 'https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png'
        const asset2Name = pool.Asset2.currency.length === 3 ? pool.Asset2.currency : convertHextoString(pool.Asset2.currency)
        const { data } = await axios.get(`https://s1.xrplmeta.org/token/${asset2Name}:${pool.Asset2.issuer}`)
        let asset2Icon = ''
        if (data?.meta?.token?.icon) {
          asset2Icon = data.meta.token.icon
        }
        const market = pool.Asset2Name?.name
        const depositedXrpB = parseFloat(pool.Balance) / 10 ** 6
        const { data: poolDetail } = await axios.get(`https://api.xrpscan.com/api/v1/amm/${pool.Account}`)
        const depositedTokenB = parseFloat(poolDetail.amount2.value)
        const { data: volumeData } = await axios.get(`https://data.xrplf.org/v1/iou/volume_data/${pool.Asset2.issuer}_${pool.Asset2.currency}?interval=1d&exclude_amm=false&only_amm=true&descending=true&limit=1`)
        const volume = volumeData[0]?.volume
        const price = parseFloat(depositedXrpB) / parseFloat(depositedTokenB)
        poolsTemp.push(
          {
            issuer, currency, asset1Name, asset2Name, asset1Icon, asset2Icon, market, depositedXrpB, depositedTokenB, volume, price
          }
        )
        setAllPoolData(poolsTemp)
      }
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const setCurBoardToToken = () => {
    setCurBoard(1)
  }
  const setCurBoardToPool = () => {
    setCurBoard(2)
  }
  const classes = useStyles()
  return (
    <Box className="all-liquidity-box">
      <Container className="all-liquidity-container">
        <Navbar />
        <CenterBox className="content" sx={{ flexDirection:"column"}}>
          <div className="flex justify-between">
            <button onClick={setCurBoardToToken} className={`panel-btn ${curBoard===1&&'selected'}`}  >Top performing Tokens</button>
            <button onClick={setCurBoardToPool} className={`panel-btn ${curBoard===2&&'selected'}`} >Top performing Liquidity Pools</button>
          </div>
          <div className="leader-content" style={{ marginTop: "5px !important" }}>
            {
              curBoard === 1 ? <PerformingTokens /> : <PerformingPool alldata={allPoolData} />
            }
          </div>
        </CenterBox>
      </Container>
    </Box>
  );
}

const PerformingTokens = () => {

  const fromTokenTrade = {
    currency: "XRP",
    account: null
  };

  const Xrplsvg = () => {
    return (
      <svg
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 15 15"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m4.193 5.155l-3.06-3.316l.734-.678l3.061 3.316a3.5 3.5 0 0 0 5.144 0l3.06-3.316l.735.678l-3.06 3.316a4.5 4.5 0 0 1-6.614 0Zm5.879 5.368a3.5 3.5 0 0 0-5.144 0l-3.06 3.316l-.735-.678l3.06-3.316a4.5 4.5 0 0 1 6.614 0l3.06 3.316l-.734.678l-3.061-3.316Z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  const Symbol = () => {
    return (
      <svg
        aria-hidden="true"
        role="img"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          fill="currentColor"
          d="M4.993 12.984a1 1 0 0 0-.531 1.848L7.15 17.52a1 1 0 1 0 1.414-1.415l-1.121-1.12h7.55a1 1 0 0 0 0-2h-10Zm14.014-1.968a1 1 0 0 0 .531-1.848L16.85 6.48a1 1 0 0 0-1.414 1.415l1.121 1.12h-7.55a1 1 0 0 0 0 2h10Z"
        ></path>
      </svg>
    );
  };

  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [paginateComment, setComment] = useState('');
  const [steps, setSteps] = useState(stepsCheckTokenPair);
  const [openLoading, setOpenLoading] = useState(false);
  const [isAmmChecked, setIsAmmChecked] = useState(false);
  const [amm, setAmm] = useState(null);
  const [sortBy, setSort] = useState('2');

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    setIsAmmChecked(false);
  }, [sortBy, alldata]);

  const getData = async () => {
    try {
      const {data} = await axios.get(`https://s1.xrplmeta.org/tokens?limit=100&include_changes=true`);
      const tokens = data.tokens
      console.log('tokens', tokens)
      const filterTokens = tokens.filter((token)=>{
        const tokenCurrency = token.currency.indexOf('000000000') === -1 ? token.currency : convertHextoString(token.currency)
        const tokenIssuer = token.issuer 

        let tokenIcon = token.meta.token.icon
        if(!tokenIcon){
          if(tokenCurrency.toLowerCase()==='xcore' && tokenIssuer.toLowerCase()==='r3dVizzUAS3U29WKaaSALqkieytA2LCoRe'.toLowerCase()) tokenIcon = xcoreIcon
          if(tokenCurrency.toLowerCase()==='xpunk' && tokenIssuer.toLowerCase()==='rHEL3bM4RFsvF8kbQj3cya8YiDvjoEmxLq'.toLowerCase()) tokenIcon = xpunkIcon
          if(tokenCurrency.toLowerCase()==='xrdoge' && tokenIssuer.toLowerCase()==='rLqUC2eCPohYvJCEBJ77eCCqVL2uEiczjA'.toLowerCase()) tokenIcon = xrdogeIcon
          if(tokenCurrency.toLowerCase()==='vgb' && tokenIssuer.toLowerCase()==='rhcyBrowwApgNonehKBj8Po5z4gTyRknaU'.toLowerCase()) tokenIcon = vgbIcon
          if(tokenCurrency.toLowerCase()==='xrshib' && tokenIssuer.toLowerCase()==='rN3EeRSxh9tLHAUDmL7Chh3vYYoUafAyyM'.toLowerCase()) tokenIcon = xrshibIcon
          if(tokenCurrency.toLowerCase()==='xoge' && tokenIssuer.toLowerCase()==='rJMtvf5B3GbuFMrqybh5wYVXEH4QE8VyU1'.toLowerCase()) tokenIcon = xogeIcon
          if(tokenCurrency.toLowerCase()==='xmeme' && tokenIssuer.toLowerCase()==='r4UPddYeGeZgDhSGPkooURsQtmGda4oYQW') tokenIcon = xmemeIcon
        }
        if(tokenIcon) return true
        return false
      }) 

      setAllData(filterTokens);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (!isAmmChecked && data.length > 0) {
      checkTokensAMMExistence(data);
    }
  }, [data, isAmmChecked]);

  const checkTokensAMMExistence = async (tokens) => {
    const updatedData = await Promise.all(
      tokens.map(async (token) => {
        const ammExists = await checkAMMExist(fromTokenTrade, {
          currency: token.currency,
          account: token.issuer,
        });
        return { ...token, amm_exists: ammExists };
      })
    );
    setData(updatedData); //changes the leaderboard 
    setIsAmmChecked(true);
  };


  const checkAMMExist = async (token1, token2) => {
    if (!token1 || !token2) {
      return;
    }
    setSteps(stepsCheckTokenPair);
    setOpenLoading(true);

    const amm_info = await getAMMInfo(token1, token2);
    console.log('amm info', amm_info)
    setOpenLoading(false);
    setAmm(!amm_info.error && amm_info.result?.result?.amm);

    return !amm_info.error && amm_info.result?.result?.amm;
  }



  const sortData = (offset) => {
    const temp = alldata;
    switch (sortBy) {
      case '0':
        temp.sort((a, b) => b.metrics.volume_24h - a.metrics.volume_24h);
        break;
      case '1':
        temp.sort((a, b) => a.metrics.volume_7d - b.metrics.volume_7d);
        break;
      case '2':
        temp.sort((a, b) => b.metrics.marketcap - a.metrics.marketcap);
        break;
      default:
        temp.sort((a, b) => a.metrics.marketcap - b.metrics.marketcap);
        break;
    }
    setIsAmmChecked(false)
    setData(temp.slice(offset, offset + 10));
  }

  const setPagenateComment = (comment) => {
    setComment(comment);
  }

  useEffect(() => {
    sortData(0);
  }, [alldata, sortBy])

  // const formatNumber = (number, digit = 2) => {
  //   number = parseFloat(number);
  //   return number.toFixed(digit);
  // }


  const changeSort = (e) => {
    setSort(e.target.value);
  }
  const handleTradeClick = (index) => {
    if (data && data[index]) {
      const { currency, issuer } = data[index];
      const url = `/swap?currency=${encodeURIComponent(currency)}&issuer=${encodeURIComponent(issuer)}`;
      console.log('url', url)
      redirect(url)
    } else {
      console.log("Token details not found.");
    }
  };

  return (

    <>
      <div className="leader-header" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <p style={{ color: '#000', fontWeight:700, fontSize:"25px" }}>Top performing tokens </p>
        <div className="custom-select">
          <select style={{ textAlign: "left", border: 'none', fontFamily:"Inter", fontWeight:700 }} onChange={(e) => changeSort(e)} value={sortBy}>
            <option value='0'>Volume↓</option>
            <option value='1'>Volume↑</option>
            <option value='2'>Market Cap↓</option>
            <option value='3'>Market Cap↑</option>
          </select>
        </div>
        <div></div>
      </div>
      <div className="leader-body" style={{ overflow: 'auto', minHeight: '550px', overflowX: 'auto' }}>
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th style={{ width: "40px", textAlign: "left" }}>#</th>
              <th style={{ width: "350px", textAlign: "left" }}>Name</th>
              <th style={{ width: "200px" }}>Price</th>
              <th style={{ width: "100px" }}>24H</th>
              <th style={{ width: "250px" }}>Volume(24h)</th>
              <th style={{ width: "250px" }}>Volume(7d)</th>
              <th style={{ width: "250px" }}>Market Cap</th>
              <th style={{ width: "150px", textAlign: "right" }}>TrustLines</th>
              {/* <th style={{ width: "250px" }}>AMM Exist</th> */}
              {/* <th style={{ textAlign: "center" }}>Action</th> */}
            </tr>
          </thead>
          <tbody style={{}}>
            {
              data && data.map((token, index) => {
                const tokenCurrency = token.currency.indexOf('000000000') === -1 ? token.currency : convertHextoString(token.currency)
                const tokenIssuer = token.issuer 

                let tokenIcon = token.meta.token.icon
                if(!tokenIcon){
                  if(tokenCurrency.toLowerCase()==='xcore' && tokenIssuer.toLowerCase()==='r3dVizzUAS3U29WKaaSALqkieytA2LCoRe'.toLowerCase()) tokenIcon = xcoreIcon
                  if(tokenCurrency.toLowerCase()==='xpunk' && tokenIssuer.toLowerCase()==='rHEL3bM4RFsvF8kbQj3cya8YiDvjoEmxLq'.toLowerCase()) tokenIcon = xpunkIcon
                  if(tokenCurrency.toLowerCase()==='xrdoge' && tokenIssuer.toLowerCase()==='rLqUC2eCPohYvJCEBJ77eCCqVL2uEiczjA'.toLowerCase()) tokenIcon = xrdogeIcon
                  if(tokenCurrency.toLowerCase()==='vgb' && tokenIssuer.toLowerCase()==='rhcyBrowwApgNonehKBj8Po5z4gTyRknaU'.toLowerCase()) tokenIcon = vgbIcon
                  if(tokenCurrency.toLowerCase()==='xrshib' && tokenIssuer.toLowerCase()==='rN3EeRSxh9tLHAUDmL7Chh3vYYoUafAyyM'.toLowerCase()) tokenIcon = xrshibIcon
                  if(tokenCurrency.toLowerCase()==='xoge' && tokenIssuer.toLowerCase()==='rJMtvf5B3GbuFMrqybh5wYVXEH4QE8VyU1'.toLowerCase()) tokenIcon = xogeIcon
                  if(tokenCurrency.toLowerCase()==='xmeme' && tokenIssuer.toLowerCase()==='r4UPddYeGeZgDhSGPkooURsQtmGda4oYQW') tokenIcon = xmemeIcon
                }
                return (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{(index + 1)}</td>
                    <td
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "20px",
                        padding: 0,
                        margin: 0,
                        height: '100%'
                      }}
                    >
                      {
                        tokenIcon ? (
                          <img
                            src={tokenIcon}
                            width="30"
                            height="30"
                            alt="token image"
                          />
                        ) : (
                          <img
                            src={PlaceholderIcon}
                            width="30"
                            height="30"
                            alt="placeholder token image"
                          />
                        )
                      }
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: "10px" }}>
                        <p style={{ margin: 0, textAlign: 'left' }}>
                          {tokenCurrency}
                        </p>
                        <p style={{ margin: 0, fontSize: 12, textAlign: 'left' }}>
                          {token.issuer ? shortAddress(token.issuer, 15) : 'Unknown'}
                        </p>
                      </div>
                    </td>
                    <td>
                    <div style={{
                        display: "flex",
                        gap: "5px",
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}>
                          <Xrplsvg /> {' '} {formatNumber(token.metrics.price)}
                      </div>
                      
                    </td>
                    <td> {
                      token.metrics.changes['24h'].price.percent > 0 ?
                        <p style={{ padding: 0, margin: 0, color: '#54D62C' }}>
                          {token.metrics.changes['24h'].price.percent.toFixed(2)}%
                        </p> :
                        <p style={{ padding: 0, margin: 0, color: '#FF6C40' }}>
                          {token.metrics.changes['24h'].price.percent.toFixed(2)}%
                        </p>
                    }
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          padding: 0,
                          alignItems: "center",
                          justifyContent: "flex-end"
                        }}
                      >
                        <Xrplsvg /> {formatNumber(token.metrics.volume_24h)}
                      </div>
                    </td>
                    <td>
                      <div style={{
                        display: "flex",
                        gap: "5px",
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}>
                        <Xrplsvg /> {formatNumber(token.metrics.volume_7d, token.metrics.volume_7d >= 100 ? 0 : 1)}
                      </div>
                    </td>
                    <td>
                      <div style={{
                        display: "flex",
                        gap: "5px",
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}>
                        <Xrplsvg /> {formatNumber(token.metrics.marketcap, token.metrics.marketcap >= 100 ? 0 : 1)}
                      </div>
                    </td>
                    <td>{token.metrics.trustlines}</td>
                    {/* <td>
                      <div style={{
                        display: "flex",
                        gap: "5px",
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}>
                        {
                          isAmmChecked ?
                            <img src={token.amm_exists ? successImage : failImage} alt={token.amm_exists ? "Yes" : "No"} width="20" height="20" />
                            :
                            <>...</>
                        }

                      </div>
                    </td> */}
                    <td style={{ display: 'flex', justifyContent: 'center', paddingRight: 10, paddingLeft: 10, alignItems: 'center' }}>
                      <Link to={`/swap?currency=${encodeURIComponent(data[index].currency)}&issuer=${encodeURIComponent(data[index].issuer)}`} style={{ textDecoration: 'none' }}>
                        <button
                          style={{ height: 30 }}
                          className="trade-btn"
                        >
                          Trade
                        </button>
                      </Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div style={{ width: '90%', margin: 'auto', paddingLeft: 10, paddingRight: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <p style={{ marginLeft: 10, marginTop: 'auto', marginBottom: 'auto', color: '#cec3ff' }}>{paginateComment}</p> */}
            <PaginatedItems itemsPerPage={10} getData={sortData} totalCount={100} setComment={setPagenateComment} />
          </div>
        </div>
      </div>
    </>

  )
}

const PerformingPool = ({ alldata, loading }) => {
  // console.log("alldata pool ", alldata);
  const fromTokenTrade = {
    currency: "XRP",
    account: null
  };

  const Xrplsvg = () => {
    return (
      <svg
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 15 15"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m4.193 5.155l-3.06-3.316l.734-.678l3.061 3.316a3.5 3.5 0 0 0 5.144 0l3.06-3.316l.735.678l-3.06 3.316a4.5 4.5 0 0 1-6.614 0Zm5.879 5.368a3.5 3.5 0 0 0-5.144 0l-3.06 3.316l-.735-.678l3.06-3.316a4.5 4.5 0 0 1 6.614 0l3.06 3.316l-.734.678l-3.061-3.316Z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  const [data, setData] = useState([]);

  const [paginateComment, setComment] = useState('');
  const [steps, setSteps] = useState(stepsCheckTokenPair);
  const [openLoading, setOpenLoading] = useState(false);
  const [isAmmChecked, setIsAmmChecked] = useState(false);
  const [amm, setAmm] = useState(null);
  const [sortBy, setSort] = useState('2');



  // useEffect(() => {
  //   setIsAmmChecked(false);
  // }, [sortBy, alldata]);


  useEffect(() => {
    // console.log('alldata', alldata[2])
  }, [alldata.length])

  // useEffect(() => {
  //   if (!isAmmChecked && alldata.length > 0) {
  //     checkTokensAMMExistence(alldata.slice(0, 10));
  //   }
  // }, [alldata, isAmmChecked]);

  // const checkTokensAMMExistence = async (tokens) => {
  //   const updatedData = await Promise.all(
  //     tokens.map(async (token) => {
  //       const ammExists = await checkAMMExist(fromTokenTrade, {
  //         currency: token.currency,
  //         account: token.issuer,
  //       });
  //       return { ...token, amm_exists: ammExists };
  //     })
  //   );
  //   // setData(updatedData); changes the leaderboard 
  //   setIsAmmChecked(true);
  // };


  // const checkAMMExist = async (token1, token2) => {
  //   if (!token1 || !token2) {
  //     return;
  //   }
  //   setSteps(stepsCheckTokenPair);
  //   setOpenLoading(true);

  //   const amm_info = await getAMMInfo(token1, token2);
  //   setOpenLoading(false);
  //   setAmm(!amm_info.error && amm_info.result?.result?.amm);

  //   return !amm_info.error && amm_info.result?.result?.amm;
  // }

  const handleTradeClick = (index) => {
    if (data && data[index]) {
      const { currency, issuer } = data[index];
      const url = `/swap?currency=${encodeURIComponent(currency)}&issuer=${encodeURIComponent(issuer)}`;
      redirect(url)
    } else {
      console.log("Token details not found.");
    }
  };


  const sortData = (offset) => {
    const temp = alldata;
    switch (sortBy) {
      case '0':
        temp.sort((a, b) => b.volume - a.volume);
        break;
      case '1':
        temp.sort((a, b) => a.volume - b.volume);
        break;
      case '2':
        temp.sort((a, b) => b.depositedXrpB - a.depositedXrpB);
        break;
      default:
        temp.sort((a, b) => a.depositedXrpB - b.depositedXrpB);
        break;
    }
    setIsAmmChecked(false)
    setData(temp.slice(offset, offset + 10));
  }

  const setPagenateComment = (comment) => {
    setComment(comment);
  }

  useEffect(() => {
    sortData(0);
  }, [alldata, sortBy])

  // const formatNumber = (number, digit = 2) => {
  //   number = parseFloat(number);
  //   return number.toFixed(digit);
  // }

  const shortAddress = (address, startLength = 5, endLength = 5) => {
    if (address === undefined) return undefined
    if (address.length < 20) return address;
    if (address.length <= startLength + endLength) {
      return address; // No need to shorten
    }
    const start = address.substring(0, startLength);
    const end = address.substring(address.length - endLength);
    return `${start}...`;
  }

  const changeSort = (e) => {
    setSort(e.target.value);
  }

  const convertHextoString = (hexString) => {
    let result = "";
    // Convert pairs of characters from the hex string to ASCII characters
    for (let i = 0; i < hexString.length; i += 2) {
      const hexPair = hexString.substr(i, 2); // Extract two characters at a time
      const asciiCode = parseInt(hexPair, 16); // Parse hexadecimal to decimal
      if (asciiCode !== 0) {
        result += String.fromCharCode(asciiCode); // Convert ASCII code to character
      }
    }
    return result;
  }

  const formatNumber = (numString, digits) => {
    if (numString === undefined) return ''
    numString = numString.toString();
    let digit = 0, i;
    if (numString.indexOf('.') !== -1) {
      for (i = numString.indexOf('.') + 1; i < numString.length; i++) {
        if (numString[i] === '0' && digit === 0) continue;
        digit++;
        if (digit === 4) break;
      }
    } else {
      return numString;
    }
    numString = numString.slice(0, i);
    if (numString[numString.length - 1] === '0') numString = numString.slice(0, numString.length - 1)
    if (numString[numString.length - 1] === '0') numString = numString.slice(0, numString.length - 1)
    const parts = numString.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join('.') + (digits > 0 ? '.' + parts[1] : '');
  }

  return (

    < >
      <div className="leader-header" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <p style={{ color: '#cec3ff' }}>Top performing pools based on : </p>
        <div className="custom-select">
          <select style={{ textAlign: "left", border: 'none' }} onChange={(e) => changeSort(e)} value={sortBy}>
            <option value='0'>Volume↓</option>
            <option value='1'>Volume↑</option>
            <option value='2'>XRP Deposited↓</option>
            <option value='3'>XRP Deposited↑</option>
          </select>
        </div>
        <div></div>
      </div>
      <div className="leader-body" style={{ overflow: 'auto', minHeight: '550px', overflowX: 'auto' }}>
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th style={{ width: "40px", textAlign: "left" }}>#</th>
              <th style={{ width: "350px", textAlign: "left" }}>Pair</th>
              <th style={{ width: "200px" }}>Name</th>
              <th style={{ width: "100px" }}>XRP <br />Deposited</th>
              <th style={{ width: "250px" }}>Token <br /> Deposited</th>
              <th style={{ width: "250px" }}>AMM Price</th>
              <th style={{ width: "250px" }}>Volume</th>
            </tr>
          </thead>
          <tbody style={{}}>
            {
              data && data.map((pool, index) => {

                return (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{(index + 1)}</td>
                    <td
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "20px",
                        padding: 0,
                        margin: 0,
                        height: '100%'
                      }}
                    >
                      {
                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}>
                          <div className="pair-token">
                            <Image className="token-icon" src={pool.asset1Icon ? pool.asset1Icon : PlaceholderIcon} alt="from" />
                            <Image className="token-icon" src={pool.asset2Icon ? pool.asset2Icon : PlaceholderIcon} alt="to" />
                          </div>
                          <p className='pair-label'>
                            {pool.asset1Name}/{pool.asset2Name}
                          </p>
                        </div>
                      }
                    </td>
                    <td>
                      <p style={{ whiteSpace: "nowrap" }}>{pool.market && pool.market + " - XRP"}</p>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}>
                        <Xrplsvg />
                        <p style={{ marginLeft: "10px" }}>{formatNumber(pool.depositedXrpB)}</p>
                      </div>

                    </td>
                    <td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}>
                          <Image className="token-icon" src={pool.asset2Icon ? pool.asset2Icon : PlaceholderIcon} alt="to" />
                          <p style={{ marginLeft: "10px" }}>{formatNumber(pool.depositedTokenB)}</p>
                        </div>
                      </td>
                    </td>
                    <td>
                      <div style={{
                        display: "flex",
                        gap: "5px",
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}>
                        <Xrplsvg /> {formatNumber(pool.price)}
                      </div>
                    </td>
                    <td>
                      <div style={{
                        display: "flex",
                        gap: "5px",
                        padding: 0,
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}>
                        <Xrplsvg /> {formatNumber(pool.volume)}
                      </div>
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center', paddingRight: 10, paddingLeft: 10, alignItems: 'center' }}>
                      <Link to={`/swap?currency=${encodeURIComponent(data[index].currency)}&issuer=${encodeURIComponent(data[index].issuer)}`} style={{ textDecoration: 'none' }}>
                        <button
                          className="trade-btn"
                        >
                          Trade
                        </button>
                      </Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div style={{ width: '90%', margin: 'auto', paddingLeft: 10, paddingRight: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>           
            <PaginatedItems itemsPerPage={10} getData={sortData} totalCount={100} setComment={setPagenateComment} />
          </div>
        </div>
      </div>
    </>

  )
}


const shortAddress = (address, startLength = 5, endLength = 5) => {
  if (address === undefined) return undefined
  if (address.length < 20) return address;
  if (address.length <= startLength + endLength) {
    return address; // No need to shorten
  }
  const start = address.substring(0, startLength);
  const end = address.substring(address.length - endLength);
  return `${start}...`;
}

export const convertHextoString = (hexString) => {
  if (hexString === undefined) return "";
  let result = "";
  // Convert pairs of characters from the hex string to ASCII characters
  for (let i = 0; i < hexString.length; i += 2) {
    const hexPair = hexString.substr(i, 2); // Extract two characters at a time
    const asciiCode = parseInt(hexPair, 16); // Parse hexadecimal to decimal
    if (asciiCode !== 0) {
      result += String.fromCharCode(asciiCode); // Convert ASCII code to character
    }
  }
  return result;
}

const formatNumber = (numString, digits) => {
  numString = numString.toString();
  let digit = 0, i;
  if (numString.indexOf('.') !== -1) {
    for (i = numString.indexOf('.') + 1; i < numString.length; i++) {
      if (numString[i] === '0' && digit === 0) continue;
      digit++;
      if (digit === 4) break;
    }
  } else {
    return numString;
  }
  numString = numString.slice(0, i);
  if (numString[numString.length - 1] === '0') numString = numString.slice(0, numString.length - 1)
  if (numString[numString.length - 1] === '0') numString = numString.slice(0, numString.length - 1)
  const parts = numString.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join('.') + (digits > 0 ? '.' + parts[1] : '');
}