import React, { useCallback, useContext, useEffect } from 'react'
import Navbar from '../../components/Navbar';
import "./style.scss";
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, IconButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ContainerBox from '../../components/ContainerBox';
import SelectCard from '../../components/SelectCard';
import InputCard from '../../components/InputCard';
import SwitcherIcon from '../../assets/images/switcher.svg';
import SwapHistory from '../../components/SwapHistory';
import { useState } from 'react';
import { calculateSwapAmount, formatCurrency, generateLPCurrencyCode, getAMMInfo, getTokenPrice, showResponseResult, tokenMetaToAmount, xrpToDrops } from '../../components/Global';
import LoadingModal from '../../components/LoadingModal';
import { AppContext, xumm } from '../..';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import sdk from '@crossmarkio/sdk';
import axios from "axios";
import { getAccountBalance } from '../../components/Global'
import { Client } from "xrpl"
import { FaArrowRight } from "react-icons/fa";
import XamanIcon from '../../assets/images/xaman2.png'
import CrossIcon from '../../assets/images/cross.png'
import CursorIcon from '../../assets/images/Cursor.png'
import LeaderBoardImage from '../../assets/images/leaderboardImg.png'

import SwapIcon from '../../assets/images/swap1.png'

const client = new Client("wss://s1.ripple.com/	");

const stepsCheckTokenPair = [
  {
    label: 'Verifying Token Pair',
    // subtitle: "Please wait while we are checking the exsiting token pairs.",
    description: "Checking if the Liquidity pools exists"
  },
  {
    label: 'Swapping tokens',
    // subtitle: process.env.REACT_APP_XRP_CONTRACT_ADDRESS,
    description: `Please confirm your transaction`,
    qr: true,
  },
];

const stepsSwapCrossMArk = [
  {
    label: 'Verifying Token Pair',
    // subtitle: "Please wait while we are checking the exsiting token pairs.",
    description: "Checking if the Liquidity pools exists"
  },
  {
    label: 'Exchanging Tokens.',
    // subtitle: "Payment for an exchange between tokens",
    description: "Confirm the transaction in your wallet."
  },
];


export default function Swap() {

  const [fromToken, setFromToken] = useState({
    currency:"XRP", balance:0, account:null, tokenImage:"https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
  });
  const [toToken, setToToken] = useState(undefined);
  const [fromAmount, setFromAmount] = useState(0);
  const [toAmount, setToAmount] = useState(0);
  const [fromTransferFee, setFromTransferFee] = useState(0);
  const [toTransferFee, setToTransferFee] = useState(0);
  const [steps, setSteps] = useState(stepsCheckTokenPair);
  const [openLoading, setOpenLoading] = useState(false);
  const [amm, setAmm] = useState(null);
  const [tradingFee, setFee] = useState(0);
  const { address, balances, setBalances } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const [alldata, setAllData] = useState([]);
  const [priceImpact, setPriceImpact] = useState(0)
  const [fromTokenPrice, setFromTokenPrice] = useState(0);
  const [toTokenPrice, setToTokenPrice] = useState(0);
  const [tokenImage, setTokenImage] = useState(null);
  const [tokenPrice, setTokenPrice] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const [ status, setStatus ] = useState(1)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const Tradecurrency = searchParams.get('currency');
  const Tradeissuer = searchParams.get('issuer');

  const fetchBalances = async () => {
    const _xaman = localStorage.getItem("XummPkceJwt");
    const _crossmark = localStorage.getItem("crossmark");
    if (_xaman) {
      const json = JSON.parse(_xaman);
      try {
        const result = await getAccountBalance(json.me.account);
        setBalances(result);
      } catch (error) {
        console.error('Failed to fetch balances for XummPkceJwt', error);
      }
    } else {
      const json = JSON.parse(_crossmark);
      try {
        const result = await getAccountBalance(json.address);
        console.log('cross mark updated balalce', result)
        setBalances(result);
      } catch (error) {
        console.error('Failed to fetch balances for crossmark', error);
      }
    }
  }

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const getData = async () => {
    try {
      const tokens = await axios.get(`https://s1.xrplmeta.org/tokens?limit=300&include_changes=true`);
      setAllData(tokens?.data?.tokens);
      return tokens.data.tokens;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }

  useEffect(() => { }, [toToken]);

  useEffect(() => {
    if (Tradecurrency && Tradeissuer) {
      getData().then((data) => {
        const matchingToken = data.find(token =>
          token.currency === Tradecurrency && token.issuer === Tradeissuer
        );
        if (matchingToken) {
          const { meta, metrics } = matchingToken;
          const { icon } = meta.token;
          const { price } = metrics;
          setTokenImage(icon);
          setTokenPrice(price);
          setToToken({
            currency: Tradecurrency,
            account: Tradeissuer,
            tokenImage: icon,
            value: price
          });
          // Set fromToken to XRP
          setFromToken({
            currency: 'XRP',
            account: null,
            tokenImage: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
          });
        } else {
          console.log("Matching Token Not Found.");
        }
      });
    }
  }, [Tradecurrency, Tradeissuer]);


  const onSwitch = async () => {
    const _fromToken = fromToken;
    const _toToken = toToken;
    setFromToken(_toToken);
    setToToken(_fromToken);
    const _amm = await checkAMMExist(_toToken, _fromToken);
    const _toAmount = toAmount;
    setFromAmount(_toAmount);
    setToAmount(await calculateSwapAmount(_amm, _toAmount));
  }

  const onChangeFromToken = useCallback(async (value) => {
    setFromAmount(value);
    setToAmount(await calculateSwapAmount(amm, value));
  },[amm])

  const checkAMMExist = async (token1, token2) => {
    if (!token1 || !token2) {
      return;
    }
    if (token1.currency === token2.currency) {
      console.log(token1.issuer, token2.issuer);
      toast.error("Please select different tokens for swap");
      return;
    }
    setSteps(stepsCheckTokenPair);
    setStatus(3)
    // setOpenLoading(true);

    const amm_info = await getAMMInfo(token1, token2);
    console.log('amminfo', amm_info)
    if (amm_info.error) {
      // Pool doesnt exist
      setStatus(4)
      setOpenLoading(false)
      return false;
    }
    setAmm(!amm_info.error && amm_info.result?.result?.amm);
    const _tradeFee = amm_info.result.result.amm.trading_fee;
    setFee(_tradeFee);
    setOpenLoading(false);

    // check trust line
    const toTokenBalance = balances.find((value) => value.currency === toToken.currency && value.account === toToken.account);
    
    if (!toTokenBalance) {
      // Set trustline
      setStatus(6)
      return !amm_info.error && amm_info.result?.result?.amm;
    }
    // Swap now
    setStatus(10)
    return !amm_info.error && amm_info.result?.result?.amm;
  }

  const closeLoadingModal = () => {
    setOpenLoading(false);
    setStatusSteps(stepsCheckTokenPair);
    setCurrentStep(0);
  }

  const setStatusSteps = () => {
    if (localStorage.getItem('XummPkceJwt')) {
      setSteps(stepsCheckTokenPair)
    } else {
      setSteps(stepsSwapCrossMArk)
    }
  }

  const handleSetTrustline = useCallback( async () => {
    setStatus(8)
    const totalSupply = await getTotalSupply(toToken.account, toToken.currency)

    if (localStorage.getItem('XummPkceJwt')) {
      const payload = await xumm.payload?.createAndSubscribe({
        TransactionType: "TrustSet",
        Account: address,
        LimitAmount: {
          currency: toToken.currency,
          issuer: toToken.account,
          value: totalSupply.toString()
        }
      }, async (event) => {
        if (Object.keys(event.data).indexOf('signed') > -1) {
          if (event.data.expires) {
            // Expires property exists, indicating expiration of sign-in
            closeLoadingModal()
            toast.error("Sign-in expired.");
            return;
          } else {
            const txid = event.data.txid;
            if (txid) {
              // Transaction success
              setStatus(10)
            } else {
              closeLoadingModal()
              // Transaction failed
              setStatus(9)
              toast.error("Transaction rejected.");
              return;
            }
          }
        }
      });

      if (payload) {
        // confirm trust line in wallet
        setStatus(7)
        xumm.xapp?.openSignRequest(payload.created);  
      }
      return
    } else {      
      setStatus(7)
      const { response } = await sdk.methods.signAndSubmitAndWait({
        TransactionType: "TrustSet",
        Account: address,
        LimitAmount: {
          currency: toToken.currency,
          issuer: toToken.account,
          value: totalSupply.toString()
        }
      });

      console.log('response', response)

      const isSuccess = showResponseResult(response);

      if (!isSuccess) {
        toast.error("Failed to set trustline.");
        setStatus(9)
        return;
      } else {
        setStatus(10)
        toast.success("Trustline set successfully.");
      }
    }
  },[address, closeLoadingModal, fromAmount, fromToken?.account, fromToken?.currency, toAmount, toToken?.account, toToken?.currency, tradingFee])

  const handleSwap = useCallback(async() => {
    if(!toAmount || toAmount === 0) return
    setStatus(12)
    console.log('toamount', toAmount)
    const fromAsset = fromToken.account ? {
      currency: fromToken.currency,
      value: fromAmount,
      issuer: fromToken.account
    } : xrpToDrops(fromAmount).toString();

    const toAsset = toToken.account ? {
      currency: toToken.currency,
      value: toAmount,
      issuer: toToken.account
    } : xrpToDrops(toAmount).toString();

    const toMaxAsset = toToken.account ? {
      currency: toToken.currency,
      value: (toAmount * 1.1).toFixed(6),
      issuer: toToken.account
    } : xrpToDrops(toAmount * 1.5).toFixed(0);

    const toMinAsset = toToken.account ? {
      currency: toToken.currency,
      value: (toAmount - (toAmount * 0.005) - (toAmount * tradingFee / 100000)).toFixed(6), // (toAmount * 0.8)
      issuer: toToken.account
    } : Math.floor(xrpToDrops(toAmount * 0.8)).toFixed(0);

    if (localStorage.getItem('XummPkceJwt')) {
      const payload = await xumm.payload?.createAndSubscribe({
        TransactionType: 'Payment',
        Account: address,
        Destination: address,
        Amount: toMaxAsset,
        SendMax: fromAsset,
        DeliverMin: toMinAsset,
        Flags: 131072,
      }, async (event) => {
        if (Object.keys(event.data).indexOf('signed') > -1) {
          if (event.data.expires) {
            // Expires property exists, indicating expiration of sign-in
            toast.error("Sign-in expired.");
            setStatus(13)
            return;
          } else {
            console.log('event',event)
            const txid = event.data.txid;
            if (txid) {  
              setStatus(14)            
              try {
                await fetch(`${process.env.REACT_APP_SERVER_URL}/swap/add`, {
                  method: "post",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    transaction_id: txid,
                    from_token: fromToken,
                    to_token: toToken,
                    from_amount: tokenMetaToAmount(fromAsset),
                    to_amount: tokenMetaToAmount(toAsset),
                  })
                });
                fetchBalances()
                closeLoadingModal()

              } catch (error) {
                closeLoadingModal()
                console.log("applying error", error);
                toast.error(error.message);
                return;
              }
            } else {
              closeLoadingModal()
              toast.error("Transaction rejected.");
              setStatus(15)
              return;
            }
          }
        }
      });

      if (payload) {
        xumm.xapp?.openSignRequest(payload.created);
        setStatus(11)
      }
    } else {
      setStatus(11)
      const { response } = await sdk.methods.signAndSubmitAndWait({
        TransactionType: "Payment",
        Account: address,
        Destination: address,
        Amount: toMaxAsset,
        SendMax: fromAsset,
        DeliverMin: toMinAsset,
        Flags: 131072,
      });
      const isSuccess = showResponseResult(response);
      if (isSuccess) {
        setStatus(14)
        await fetch(`${process.env.REACT_APP_SERVER_URL}/swap/add`, {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            transaction_id: response.data.resp?.result?.hash,
            from_token: fromToken,
            to_token: toToken,
            from_amount: tokenMetaToAmount(fromAsset),
            to_amount: tokenMetaToAmount(toAsset),
          })
        });
        fetchBalances()
      }else{
        setStatus(13)
      }
    }
  },[address, closeLoadingModal, fetchBalances, fromAmount, fromToken, toAmount, toToken, tradingFee])

  const onSubmit = useCallback(async () => {
    if(status === 6) {
      await handleSetTrustline()
      return
    }
    
    if(status === 9) {
      setStatus(6)
      return
    }

    if(status === 10) {
      await handleSwap()
      return
    }

    if(status === 13 || status === 14 || status === 15){
      setStatus(10)
      return 
    }

  },[handleSetTrustline, handleSwap, status])

  function isHexadecimal(str) {
    const hexRegex = /^[0-9A-Fa-f]+$/;
    return hexRegex.test(str);
  }

  function hexToText(hex) {
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return str;
  }

  useEffect(() => {
    if (!amm || !fromAmount || !toAmount) return
    const fromTokenBalance = balances.find((value) => value.currency === fromToken.currency && value.account === fromToken.account);
    if (!fromTokenBalance || fromTokenBalance.balance - fromAmount < 0) {
      setStatus(5)
      return;
    }else{
      if(status === 5) setStatus(10)
    }

    const fromAMMTokenAmount = parseFloat(tokenMetaToAmount(amm.amount))
    const toAMMTokenAmount = parseFloat(tokenMetaToAmount(amm.amount2))
    const k = fromAMMTokenAmount * toAMMTokenAmount

    console.log('k', k)

    const fromAMMTokenAmountAfterTrade = fromAMMTokenAmount + fromAmount * ((1 - tradingFee / 100000))
    const toAMMTokenAmountAfterTrade = k / fromAMMTokenAmountAfterTrade

    const effectivePrice = parseFloat(toAMMTokenAmountAfterTrade) / parseFloat(fromAMMTokenAmountAfterTrade)
    const preTradePrice = parseFloat(toAMMTokenAmount) / parseFloat(fromAMMTokenAmount)
    console.log(effectivePrice, preTradePrice)
    const priceImpact = ((effectivePrice - preTradePrice) / preTradePrice) * 100
    setPriceImpact(priceImpact)

  }, [fromAmount, toAmount, amm, tradingFee, balances, fromToken?.currency, fromToken?.account, status])

  async function getTotalSupply(issuer, symbol) {
    const { data } = await axios.get(`https://s1.xrplmeta.org/token/${symbol}:${issuer}`)
    return parseFloat(data.metrics.supply)
  }

  async function setTransferFees(fromToken, toToken) {
    if (fromToken.currency === "XRP") {
      setFromTransferFee(0)
    } else {
      setFromTransferFee(await getTransferFee(fromToken.account))
    }
    if (toToken.currency === "XRP") {
      setToTransferFee(0)
    } else {
      setToTransferFee(await getTransferFee(toToken.account))
    }
  }

  useEffect(() => {
    if (!fromToken || !toToken) return;
    checkAMMExist(fromToken, toToken);
    setTransferFees(fromToken, toToken);
  }, [fromToken, toToken])

  useEffect(()=>{
    if(address?.length>0 && status===1) setStatus(2)
    if(address?.length === 0) setStatus(1)
  },[address, status])


  return (
    <>
    <Box className="swap-box">
      <Navbar />
      <LoadingModal open={openLoading} steps={steps} currentStep={currentStep} />
      <Container className="swap-container">
        <ContainerBox title="SWAP" description="Trade Tokens Instantly"  buttonText="SWAP NOW" onClick={onSubmit} status={status}>
          <Box sx={{ position: 'relative', marginBottom: "20px" }}>
            <InputCard token={fromToken} amount={fromAmount} setToken={setFromToken} onChange={onChangeFromToken} />
            <InputCard token={toToken} amount={toAmount} setToken={setToToken}  disabled />
            <Box className="switcher" component="img" src={SwapIcon} alt='switcher' onClick={onSwitch} />
          </Box>
         
         {fromToken && toToken && ( 
          <div className='flex justify-center'>
            <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{ background: 'transparent', boxShadow: 'none', width:"420px" }}>
              <div className='flex justify-center'>
                <AccordionSummary
                  sx={{width:'200px'}}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<IconButton className='expand-icon'><ExpandMore sx={{ color: "black", justifyContent: "center" }} /></IconButton>}
                >
                  <Typography className="summary-text font-bold">Trade Summary</Typography>
                </AccordionSummary>
              </div>
              
              <AccordionDetails sx={{ padding:"15px 50px 20px", borderRadius:"16px", background:"#F5F5F5"}}>
                <Box sx={{ color: "#6E7273", }}>
                  {/* <Typography>Slippage tolerance:<span>0.5%</span></Typography> */}
                  <div className='flex justify-between'>
                    <Typography >Min Received</Typography>
                    <span className='text-[#0F1110] font-medium text-[19px]'>
                      {toAmount ? `${(toAmount - (toAmount * 0.005) - (toAmount * tradingFee / 100000)).toFixed(6).replace(/(\.\d*?[1-9])0+$/, '$1')}` : ''}
                    </span>
                  </div>
                  
                  <div className='flex justify-between'>
                    <Typography >Price Impact </Typography>
                    <span className={`${priceImpact < -10 ? 'text-red':'text-[#0F1110]'}  font-medium text-[19px]`}>
                      {priceImpact && priceImpact.toFixed(3)} %
                    </span>
                  </div>

                  <div className='flex justify-between'>
                   <Typography >Pool Fee</Typography><span className='text-[#0F1110] font-medium text-[19px]'>{tradingFee ? `${tradingFee / 1000}%` : ``}</span>
                  </div>
                  <div className='flex justify-between'>
                    <Typography >{fromToken ? `price for 1 ${isHexadecimal(fromToken.currency) ? hexToText(fromToken.currency) : fromToken.currency}:` : ''}      </Typography>
                    <span className='text-[#0F1110] font-medium text-[19px]'>
                      {toAmount ? `${(!isNaN(toAmount / fromAmount) ? (toAmount / fromAmount).toFixed(toAmount / fromAmount > 0 ? 6 : 6).replace(/(\.\d*?[1-9])0+$/, '$1') : '0')}` : ''}
                    </span>            
                  </div>
                  <div className='flex justify-between'>
                    <Typography >{toToken ? `price for 1 ${isHexadecimal(toToken.currency) ? hexToText(toToken.currency) : toToken.currency}:` : ''} </Typography>
                    <span className='text-[#0F1110] font-medium text-[19px]'>
                      {fromAmount && toAmount ? `${(fromAmount / toAmount).toFixed(toAmount > 1 ? 6 : 6).replace(/(\.\d*?[1-9])0+$/, '$1')}` : ''}
                    </span>
                  </div>
                  <div className='flex justify-between'>

                    <Typography >{fromToken && isHexadecimal(fromToken.currency) ? `${hexToText(fromToken.currency)} transfer fee` : `${fromToken.currency} transfer fee`}     </Typography>
                    <span className='text-[#0F1110] font-medium text-[19px]'>{fromTransferFee}%</span>
             
                  </div>
                  <div className='flex justify-between'>
                    <Typography >{toToken && isHexadecimal(toToken.currency) ? `${hexToText(toToken.currency)} transfer fee` : `${toToken.currency} transfer fee`}  </Typography>
                    <span className='text-[#0F1110] font-medium text-[19px]'>{toTransferFee}%</span>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          </div>

          )}
        </ContainerBox>
      </Container>
      {/* <SwapHistory /> */}
    </Box>
    <Box className="swap-desc px-[142px] pt-[37px] pb-[97px]">
      <div className='flex space-x-8 items-center justify-center'>
        <p className=' title'>
          How to Trade?
        </p>
        <div className='flex flex-col desc-box p-3'>
          <div className='flex space-x-1 justify-center'>
            <img src={XamanIcon} sizes='10' alt='xaman'/>
            <img src={CrossIcon} sizes='10' alt='crossmark'/>
          </div>  
          <p className='text-center label'>Connect Wallet <br/> (Top Right)</p>
        </div>
        <FaArrowRight width={57} height={48}/>
        <div className='flex flex-col desc-box p-3'>
          <div className='flex space-x-1 justify-center'>
            <img src={CursorIcon} sizes='10' alt='cursor'/>
          </div>  
          <p className='text-center label'>Select Tokens To<br/> Trade</p>
        </div>
        <FaArrowRight width={57} height={48}/>
        <div className='flex flex-col desc-box p-3'>
          <div className='flex space-x-1 justify-center'>
            <img src={SwapIcon} sizes='10' alt='swao'/>
          </div>  
          <p className='text-center label'>Click<br/> SWAP NOW</p>
        </div>
      </div>

      <p className='title pt-[112px] pb-[70px]'>Not Sure What to trade?</p>
      <div className='flex space-x-4 justify-center'>
          <div className='flex flex-col w-[650px] items-center'>
            <p className='title'>Check out our leaderboard to see the top performing tokens</p>
            <a className='link-box' href='/leaderboard'><p className='label'>Go to leaderboard</p></a>
          </div>
          <div>
            <img src={LeaderBoardImage} width={800} alt='leaderboarder'/>
          </div>
      </div>
    </Box>
    </>
    
  )
}

export async function getTransferFee(issuer) {
  if (issuer === null || issuer === undefined) return 0
  try {
    await client.connect()
    const data = await client.request({
      "command": "account_info",
      "account": issuer,
      "ledger_index": "current",
    })
    await client.disconnect()
    if (data.result?.account_data?.TransferRate) {
      const tranferRate = (data.result.account_data.TransferRate - 1000000000) / 10000000.0
      return tranferRate
    } else {
      return 0
    }
  } catch (err) {
    console.log(err)
    return 0
  }


}