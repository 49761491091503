import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Link from "../Link";
import './style.scss';
import StarIcon from "../../assets/images/kyc.png"


export default function KycModal({ open, closeModal }) {

    return (
        <Modal
            className="modal-wrapper"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box className="modal-content" style={{
                    width : '100vh',

                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }} >

                        <h1 style={{
                            textAlign: 'center',
                            color: 'red'
                        }}>You must use the verified wallet</h1>
                        <p style={{
                            fontSize: 20
                        }}>To launch a project, you must use a verified wallet. Please refer to the image below.</p>
                        <img src={StarIcon} style={{
                            width: '100%'
                        }}/>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 6,
                        }} >
                            <p style={{
                                fontSize: 20
                            }}>
                                You can check that in
                            </p>
                            <p style={{
                                color: 'blue',
                                fontWeight: 700,
                                fontSize: 20
                            }}>
                                <Link href="https://support.xumm.app/hc/en-us/articles/4474074425746" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', fontWeight: 700, fontSize: 20 }}>
                                    here
                                </Link>
                            </p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <button className='btn' style={{ width: '150px', padding: 10, color: 'white', fontWeight: 700 }} onClick={() => closeModal()}>Close</button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}
