import React, { useContext, useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar';
import "./style.scss";
import { Box, Container, Input, Slider, Tab, Tabs, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, IconButton  } from '@mui/material';
import ContainerBox from '../../components/ContainerBox';
import SelectCard from '../../components/SelectCard';
import InputCard from '../../components/InputCard';
import SwitcherIcon from '../../assets/images/switcher.svg';
import SelectPair from '../../components/SelectPair';
import DropDownIcon from "../../assets/images/dropdown.svg";
import CenterBox from '../../components/CenterBox';
import { useState } from 'react';
import { getTokenPrice, tokenToInfoJson, tokenToJson, tokenMetaToAmount, tokenMetaToCurrency, calculateLPTokenAmount, calculateLPToAmount, calculateLPFromAmount, showResponseResult, getAccountBalance, dropsToXrp, getAMMInfo } from '../../components/Global';
import { AppContext } from '../..';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import sdk from '@crossmarkio/sdk';
import LoadingModal from '../../components/LoadingModal';
import { ExpandMore } from '@mui/icons-material';
import { xumm } from '../..';
import { getCSRFToken, getJWT } from '../../utils';

const stepsCheckTokenPair = [
  {
    label: 'Checking Exsiting Token Pairs',
    // subtitle: "Please wait while we are checking the exsiting token pairs.",
    description: "Please wait while we check if the liquidity pool exists"
  },
];

const stepsRemoveLP = [
  {
    label: 'Checking account information.',
    subtitle: "Checking your account's LP token balance",
    description: "This step is responsible for checking your account's LP token balance."
  },
];

const stepsCreateLP = [
  {
    label: 'Saving New Liquidity Pool.',
    subtitle: "Saving the new liquidity pool to our database.",
    description: "This step is responsible for saving the new liquidity pool to our database."
  },
];

const stepsXamanCreateLp = [
  {
    label: 'Checking Exsiting Token Pairs',
    // subtitle: "Please wait while we are checking the exsiting token pairs.",
    description: "Please wait while we check if the liquidity pool exists",
    qr: true,
  },
]

const stepsXamanRemoveLp = [
  {
    label: 'Removing New Liquidity Pool.',
    subtitle: "Removing the new liquidity pool to our database.",
    description: "This step is responsible for saving the new liquidity pool to our database.",
    qr: true,
  },
]

const stepsXamanAddLp = [
  {
    label: 'Adding New Liquidity Pool.',
    subtitle: "Adding the new liquidity pool to our database.",
    description: "This step is responsible for saving the new liquidity pool to our database.",
    qr: true,
  },
]

export default function AddLiquidity() {
  const { address, balances, setBalances, manageFromToken, manageToToken } = useContext(AppContext);
  const [fromToken, setFromToken] = useState({
    currency:"XRP", balance:0, account:null, tokenImage:"https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
  });
  const [toToken, setToToken] = useState(null);
  const [dollarFromAmount, setDollarFromAmount] = useState(0);
  const [dollarToAmount, setDollarToAmount] = useState(0);
  const [tokenFromAmount, setTokenFromAmount] = useState(0);
  const [tokenToAmount, setTokenToAmount] = useState(0);
  const [openSummary, setOpenSummary] = useState(false);
  const [tab, setTab] = useState(2);
  const [ammExisted, setAmmExisted] = useState();
  const [fee, setFee] = useState(false);
  const [removePercent, setRemovePercent] = useState(0);
  const [fromRemoveAmount, setFromRemoveAmount] = useState(0);
  const [toRemoveAmount, setToRemoveAmount] = useState(0);
  const [lpRemoveAmount, setLPRemoveAmount] = useState(0);
  const [openLoading, setOpenLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(stepsCheckTokenPair);
  const [expanded, setExpanded] = useState(false);

  // const sdk = window.xrpl.crossmark;
  useEffect(() => {
    if (manageFromToken.current && manageToToken.current) {
      checkAMMExist(manageFromToken.current, manageToToken.current);
      manageFromToken.current = null;
      manageToToken.current = null;
    }

  }, [])

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const onSelectFrom = (token) => {
    setFromToken(token);
    onChangeFromToken(0);
    checkAMMExist(token, toToken);
  }

  const onSelectTo = (token) => {
    setToToken(token);
    onChangeToToken(0);
    checkAMMExist(fromToken, token);
  }

  const onSwitch = () => {
    const tempToken = fromToken;
    setFromToken(toToken);
    setToToken(tempToken);
    setDollarFromAmount((tokenFromAmount * getTokenPrice(toToken)).toFixed(2));
    setDollarToAmount((tokenToAmount * getTokenPrice(tempToken)).toFixed(2));
    setTokenFromAmount(calculateLPFromAmount(ammExisted, tokenFromAmount));
    setTokenToAmount(calculateLPToAmount(ammExisted, tokenToAmount));
  }

  const onChangeFromToken = (value) => {
    setTokenFromAmount(value);
    if (ammExisted && ammExisted.trading_fee) {
      setTokenToAmount(calculateLPToAmount(ammExisted, value));
    }
    setDollarFromAmount((value * getTokenPrice(fromToken)).toFixed(2));
    setTab(0);
  }

  const onChangeToToken = (value) => {
    setTokenToAmount(value);
    if (ammExisted && ammExisted.trading_fee) {
      setTokenFromAmount(calculateLPFromAmount(ammExisted, value));
    }
    setDollarToAmount((value * getTokenPrice(toToken)).toFixed(2));
    setTab(0);
  }

  const onFeeChange = (e) => {
    setFee(e.target.value);
  }

  const onChangeRemovePercent = (e, value) => {
    setRemovePercent(value);
    calculateRemovalTokens(value);
  }

  const calculateRemovalTokens = (percent) => {
    if (balances.length === 0) return;

    const lp_currency = ammExisted.lp_token.currency;
    const lp_my_amount = balances.find(item => item.currency === lp_currency)?.balance || 0;
    const lp_total_amount = ammExisted.lp_token.value;
    const lp_tokens_to_remove = lp_my_amount * (percent / 100);

    const assetA_total_amount = ammExisted.amount?.value || dropsToXrp(ammExisted.amount);
    const assetB_total_amount = ammExisted.amount2?.value || dropsToXrp(ammExisted.amount2);

    const assetA_to_remove = (assetA_total_amount / lp_total_amount) * lp_tokens_to_remove;
    const assetB_to_remove = (assetB_total_amount / lp_total_amount) * lp_tokens_to_remove;

    setLPRemoveAmount(lp_tokens_to_remove);
    setFromRemoveAmount(assetA_to_remove);
    setToRemoveAmount(assetB_to_remove);
  }

  const onChangeTab = async (value) => {
    setTab(value);
    if (value === 1) {
      setSteps(stepsRemoveLP);
      setOpenLoading(true);
      if (balances.length === 0) {
        const _balance = await getAccountBalance(address);
        setBalances(_balance);
      }
      calculateRemovalTokens(removePercent);
      setOpenLoading(false);
    }
  }

  const checkAMMExist = async (token1, token2) => {
    if (!token1 || !token2) {
      return;
    }
    setSteps(stepsCheckTokenPair);
    setOpenLoading(true);

    const amm_info = await getAMMInfo(token1, token2);

    if (!amm_info.error && amm_info.result?.result?.amm) {
      setAmmExisted(amm_info.result.result.amm);
    } else {
      setAmmExisted(null);
    }

    setOpenLoading(false);
  }

  const onSubmit = async () => {
    setOpenLoading(true)
    if (tab !== 1) {
      if (!fromToken || !toToken) {
        toast.error("Please select a token");
        return;
      }

      const toBalanceInfo = balances?.find((value) => {
        if (value.currency === toToken.currency && value.account === toToken.account) {
          return true;
        }
      })
      const fromBalanceInfo = balances?.find((value) => {
        if (value.currency === fromToken.currency && value.account === fromToken.account) {
          return true;
        }
      })

      if (Number(fromBalanceInfo.balance) < tokenFromAmount || Number(toBalanceInfo.balance) < tokenToAmount) {
        toast.error("Insufficient Funds");
        return;
      }

      if (tokenFromAmount === 0 || tokenToAmount === 0) {
        toast.error("Please input the valid amount of token");
        return;
      }

      if (!ammExisted) {
        if (!fee) {
          toast.error("Please add the initial fee.");
          return;
        } else if (fee < 0 || fee > 1) {
          toast.error("Invalid fee. Should be 0~1%.");
          return;
        }
      }

    }
    
    const jsonToToken = tokenToJson(toToken, tokenToAmount);
    const jsonFromToken = tokenToJson(fromToken, tokenFromAmount);
    const metaToToken = tokenToInfoJson(toToken);
    const metaFromToken = tokenToInfoJson(fromToken);

    if (!ammExisted) {
      if(localStorage.getItem('XummPkceJwt')){
        setSteps(stepsXamanCreateLp);
        const payload = await xumm.payload?.createAndSubscribe({
          TransactionType: 'AMMCreate',
          Account: address,
          Amount: jsonFromToken,
          Amount2: jsonToToken,
          TradingFee: fee * 1000,
          Fee: "2000000",
        }, async (event) => {
          if (Object.keys(event.data).indexOf('signed') > -1) {
            if (event.data.expires) {
              // Expires property exists, indicating expiration of sign-in
              setOpenLoading(false)
              toast.error("Sign-in expired.");
              return;
            } else {
              setOpenLoading(false)              
            }
          }
        });
        if (payload) {
          xumm.xapp?.openSignRequest(payload.created);
          const _steps = [...stepsXamanCreateLp];
          _steps[0].qr_link = payload.created.refs.qr_png;
          setSteps(_steps);
        }
      }else{
        // Create Liquidity
        const { response } = await sdk.methods.signAndSubmitAndWait({
          TransactionType: 'AMMCreate',
          Account: address,
          Amount: jsonFromToken,
          Amount2: jsonToToken,
          TradingFee: fee * 1000,
          Fee: "2000000",
        });

        const isSuccess = showResponseResult(response);
        if (isSuccess) {
          try {
            setSteps(stepsCreateLP);
            setOpenLoading(true);
            await fetch(`${process.env.REACT_APP_SERVER_URL}/pool/add`, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                
                "authorization": `Bearer ${getJWT()}`
              },
              body: JSON.stringify({
                token: fromToken,
                token2: toToken,
              })
            });

            toast.success("Successfully added.");
          } catch (e) {
            console.log(e);
            toast.error(e.message || e);
          } finally {
            setOpenLoading(false);
          }
        }
      }
      return;
    }

    if (tab === 0) {
      // Add Liquidity
      if(localStorage.getItem('XummPkceJwt')){
        setSteps(stepsXamanAddLp)
        console.log(address, jsonFromToken, jsonToToken, metaFromToken, metaToToken)
        const payload = await xumm.payload?.createAndSubscribe({
          TransactionType: 'AMMDeposit',
          Account: address,
          Amount: jsonFromToken,
          Amount2: jsonToToken,
          Asset: metaFromToken,
          Asset2: metaToToken,
          Flags: 1048576
        }, async (event) => {
          if (Object.keys(event.data).indexOf('signed') > -1) {
            if (event.data.expires) {
              // Expires property exists, indicating expiration of sign-in
              setOpenLoading(false)
              toast.error("Sign-in expired.");
              return;
            } else {
              setOpenLoading(false)              
            }
          }
        });
        if (payload) {
          xumm.xapp?.openSignRequest(payload.created);
          const _steps = [...stepsXamanAddLp];
          _steps[0].qr_link = payload.created.refs.qr_png;
          setSteps(_steps);
        }
    
      }else{
        let { response } = await sdk.methods.signAndSubmitAndWait({
          TransactionType: 'AMMDeposit',
          Account: address,
          Amount: jsonFromToken,
          Amount2: jsonToToken,
          Asset: metaFromToken,
          Asset2: metaToToken,
          Flags: 1048576
        });
  
        showResponseResult(response);
      }
     
      return;
    } else {
      if (fromRemoveAmount.toFixed(2) / 1 === 0 && toRemoveAmount.toFixed(2) / 1 === 0) {
        toast.error("No LP tokens to remove.")
        return;
      }

      // Remove Liquidity
      const jsonFromRemoveToken = tokenToJson(fromToken, fromRemoveAmount.toFixed(6));
      const jsonToRemoveToken = tokenToJson(toToken, toRemoveAmount.toFixed(6));

      if(localStorage.getItem('XummPkceJwt')){
        setSteps(stepsXamanRemoveLp)
        const payload = await xumm.payload?.createAndSubscribe({
          TransactionType: 'AMMWithdraw',
          Account: address,
          Amount: jsonFromRemoveToken,
          Amount2: jsonToRemoveToken,
          Asset: metaFromToken,
          Asset2: metaToToken,
          Fee: "2000000",
          Flags: 1048576,
        }, async (event) => {
          if (Object.keys(event.data).indexOf('signed') > -1) {
            if (event.data.expires) {
              // Expires property exists, indicating expiration of sign-in
              setOpenLoading(false)
              toast.error("Sign-in expired.");
              return;
            } else {
              setOpenLoading(false)              
            }
          }
        });
        if (payload) {
          xumm.xapp?.openSignRequest(payload.created);
          const _steps = [...stepsXamanRemoveLp];
          _steps[0].qr_link = payload.created.refs.qr_png;
          setSteps(_steps);
        }
      }else{
        let { response } = await sdk.methods.signAndSubmitAndWait({
          TransactionType: 'AMMWithdraw',
          Account: address,
          Amount: jsonFromRemoveToken,
          Amount2: jsonToRemoveToken,
          Asset: metaFromToken,
          Asset2: metaToToken,
          Fee: "2000000",
          Flags: 1048576,
        });
  
        showResponseResult(response);
      }

      return;
    }

  }

  return (
    <Box className="create-box">
      <Navbar />
      <LoadingModal open={openLoading} steps={steps} currentStep={currentStep} />
      <Container className="create-container">
        <ContainerBox className="container-box"
          title='LIQUIDITY'
          buttonText={`${ toToken===null ? 'Please Select' : !ammExisted ? "Confirm Create" : tab === 0 ? "Confirm Deposit" : "Confirm Withdraw"}`}
          onClick={onSubmit}
        >          
          {
              toToken !=null &&
              !ammExisted? 
              <div className='w-full absolute top-[80px] flex justify-center pr-[66px]'>
                <div className='text-center text-black border border-black rounded-[17px] w-fit  mx-auto px-8 '>
                  <p className='text-[32px] font-bold'>Creating New Pool</p>
                  <p>(LP does not exist)</p> 
                </div>
              </div>
              :
              <Tabs className='tabs' value={tab} onChange={(e, value) => onChangeTab(value)}>
                <Tab className='tab' label="Deposit" disabled={!ammExisted} />
                <Tab className='tab right' label="Withdraw" disabled={!ammExisted} />
              </Tabs>
            }  
          <Box sx={{ marginBottom: "20px" }}>                                 
            {tab===2?
                <Box sx={{ position: 'relative', marginBottom: "20px", paddingTop:"50px"}}>
                  <InputCard amountInDollar={dollarFromAmount} amount={tokenFromAmount} token={fromToken} setToken={onSelectFrom} onChange={onChangeFromToken} nomax />
                  <InputCard amountInDollar={dollarToAmount} amount={tokenToAmount} token={toToken} setToken={onSelectTo} onChange={onChangeToToken} nomax />
                  {/* <Box className="switcher" component="img" src={SwitcherIcon} alt='switcher'
                    onClick={onSwitch}
                  /> */}
                </Box>:
            tab === 0 ?
              <>
                <Box sx={{ position: 'relative', marginBottom: "20px", paddingTop:"70px" }}>
                  <InputCard amountInDollar={dollarFromAmount} amount={tokenFromAmount} token={fromToken} setToken={onSelectFrom} onChange={onChangeFromToken} nomax />
                  <InputCard amountInDollar={dollarToAmount} amount={tokenToAmount} token={toToken} setToken={onSelectTo} onChange={onChangeToToken} nomax />
                  {!ammExisted &&
                    <div className='fee-config'>
                      <Typography className='fee-desc'>Trading Fee:</Typography>
                      <NumericFormat
                        className='fee-input'
                        onValueChange={(values) => {
                          onFeeChange({
                            target: {
                              value: values.value,
                            },
                          });
                        }}
                        valueIsNumericString
                        suffix=" %"
                        allowNegative={false}
                        decimalScale={3}
                        placeholder='0.00 %'
                      />
                    </div>
                  }
                  {/* <Box className="switcher" component="img" src={SwitcherIcon} alt='switcher'
                    onClick={onSwitch}
                  /> */}
                </Box>
                <div className='flex justify-center'>
                  <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{ background: 'transparent', boxShadow: 'none', width:"420px" }}>
                    <div className='flex justify-center'>
                      <AccordionSummary
                        sx={{width:'150px'}}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={<IconButton className='expand-icon'><ExpandMore sx={{ color: "black", justifyContent: "center" }} /></IconButton>}
                      >
                        <Typography className="summary-text font-bold">Summary</Typography>
                      </AccordionSummary>
                    </div>
                    
                    <AccordionDetails sx={{ padding:"15px 50px 20px", borderRadius:"16px", background:"#F5F5F5"}}>
                      <Box sx={{ color: "#6E7273", }}>
                        {/* <Typography>Slippage tolerance:<span>0.5%</span></Typography> */}
                        <div className='flex justify-between'>
                          <Typography >LP tokens to receive:</Typography>
                          <span className='text-[#0F1110] font-medium text-[19px]'>
                            {calculateLPTokenAmount(ammExisted, tokenFromAmount, tokenToAmount)}
                          </span>
                        </div>
                        
                        <div className='flex justify-between'>
                          <Typography >{tokenMetaToCurrency(ammExisted?.amount)} in pool: </Typography>
                          <span className='text-[#0F1110]  font-medium text-[19px]'>
                          {tokenMetaToAmount(ammExisted?.amount)}
                          </span>
                        </div>

                        <div className='flex justify-between'>
                        <Typography >{tokenMetaToCurrency(ammExisted?.amount2)} in pool:</Typography>
                        <span className='text-[#0F1110] font-medium text-[19px]'>{tokenMetaToAmount(ammExisted?.amount2)}</span>
                        </div>
                        <div className='flex justify-between'>
                          <Typography >Total LP tokens:</Typography>
                          <span className='text-[#0F1110] font-medium text-[19px]'>
                          {tokenMetaToAmount(ammExisted?.lp_token)}
                          </span>            
                        </div>
                        <div className='flex justify-between'>
                          <Typography >Pool trading fee:</Typography>
                          <span className='text-[#0F1110] font-medium text-[19px]'>
                          {ammExisted?.trading_fee ? (ammExisted.trading_fee / 1000) : 0} %
                          </span>
                        </div>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
              :
              <>
                <Box sx={{ marginBottom: "20px", textAlign: "center", paddingTop:"70px" }}>
                  <Typography sx={{ fontSize: "26px", fontWeight: "600", color: "#000" }}>
                    {removePercent}%
                  </Typography>
                  <Slider sx={{color:"#000"}} value={removePercent} onChange={onChangeRemovePercent} />
                </Box>
                <p className='text-center font-bold text-[24px] '>You will receive:</p>
                <Box sx={{ position: 'relative', marginBottom: "20px"}}>
                  <InputCard amountInDollar={dollarFromAmount} amount={fromRemoveAmount.toFixed(4)} token={fromToken} setToken={onSelectFrom} onChange={onChangeFromToken} nomax />
                  <InputCard amountInDollar={dollarToAmount} amount={toRemoveAmount.toFixed(4)} token={toToken} setToken={onSelectTo} onChange={onChangeToToken} nomax />
                  {/* <Box className="switcher" component="img" src={SwitcherIcon} alt='switcher'
                    onClick={onSwitch}
                  /> */}
                </Box>
                <div className='flex justify-center'>
                  <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{ background: 'transparent', boxShadow: 'none', width:"420px" }}>
                    <div className='flex justify-center'>
                      <AccordionSummary
                        sx={{width:'150px'}}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={<IconButton className='expand-icon'><ExpandMore sx={{ color: "black", justifyContent: "center" }} /></IconButton>}
                      >
                        <Typography className="summary-text font-bold">Summary</Typography>
                      </AccordionSummary>
                    </div>
                    
                    <AccordionDetails sx={{ padding:"15px 50px 20px", borderRadius:"16px", background:"#F5F5F5"}}>
                      <Box sx={{ color: "#6E7273", }}>
                        {/* <Typography>Slippage tolerance:<span>0.5%</span></Typography> */}
                        <div className='flex justify-between'>
                          <Typography >Redeemed LP tokens:</Typography>
                          <span className='text-[#0F1110] font-medium text-[19px]'>
                          {lpRemoveAmount.toFixed(2)}
                          </span>
                        </div>
                        
                        <div className='flex justify-between'>
                          <Typography >{tokenMetaToCurrency(ammExisted?.amount)} to redeem: </Typography>
                          <span className='text-[#0F1110]  font-medium text-[19px]'>
                          {fromRemoveAmount.toFixed(2)}
                          </span>
                        </div>

                        <div className='flex justify-between'>
                        <Typography >{tokenMetaToCurrency(ammExisted?.amount2)} to redeem:</Typography>
                        <span className='text-[#0F1110] font-medium text-[19px]'>{toRemoveAmount.toFixed(2)}</span>
                        </div>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            }
          </Box>
        </ContainerBox>
      </Container>
    </Box >
  )
}